<script>
  import { marked } from "marked";
  import DotCarousel from "./DotCarousel.svelte";
  import he from "he";

  export let accentColor = "";
  export let message = [];
</script>

<!-- Message Container -->
<div class="verbaflo-property-message-container">
  <p class="verbaflo-property-message-text">
    {#await marked.parse(message?.message?.replace(/\n/g, "</br>")) then parsedMessage}
      {@html he
        .decode(parsedMessage)
        .replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')
        .replace(
          /(\+\d{1,3}[\s\-.]?)?((?:[0-9][\s\-.]?){6,}[0-9])/g,
          '<a href="tel:$&">$&</a>'
        )}
    {/await}
  </p>
</div>

<!-- Carousel Container -->
<div class="verbaflo-property-carousel-container">
  <DotCarousel>
    {#each message?.property_json as property}
      <div class="verbaflo-property-card">
        <div class="verbaflo-property-card-image-container">
          <img
            class="verbaflo-property-card-image"
            src={typeof property?.image === "string"
              ? property?.image
              : property?.image?.length > 0
                ? property?.image[0]
                : "https://crm.londonist.co.uk/uploads/1/campus/533/o_1i4rnda2r1vc0140p1mhi16iighj57.jpg"}
            alt={property?.property_name}
          />
        </div>
        <div class="verbaflo-property-card-details">
          <h3 class="verbaflo-property-card-title">
            {property?.property_name}
          </h3>
          <div
            class="verbaflo-property-card-info-row verbaflo-property-card-info-row--pt"
          >
            <span class="verbaflo-property-label">Type:</span>
            <p class="verbaflo-property-value">{property?.room_name}</p>
          </div>
          {#if property?.subconfigs && property?.subconfigs?.length > 0}
            <div class="verbaflo-property-card-info-row">
              <span class="verbaflo-property-label">Rent:</span>
              <p class="verbaflo-property-value">
                £{property?.subconfigs[0]?.price}
              </p>
            </div>
          {/if}
          <div class="verbaflo-property-card-amenities">
            <p title={property?.amenities?.join(", ")}>
              <span class="verbaflo-property-label">Ammenities:</span>
              {property?.amenities?.slice(0, 4).join(", ")}
            </p>
          </div>
          <button
            style="background-color: {accentColor} !important;"
            class="verbaflo-property-card-button"
            on:click={() => {
              window.open(
                property?.source_link,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Visit Property
          </button>
        </div>
      </div>
    {/each}
  </DotCarousel>
</div>

<style>
  :global(.verbaflo-property-message-container) {
    background-color: #f6f6f6 !important;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
    padding: 10px;
    max-width: 280px;
  }

  :global(.verbaflo-property-message-text) {
    font-size: 0.875rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  :global(.verbaflo-property-carousel-container) {
    margin-top: 0.25rem;
    background-color: #f6f6f6 !important;
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.06) !important;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
    padding: 10px;
    max-width: 280px;
    overflow: hidden;
  }
  :global(.verbaflo-property-card) {
    background-color: #ffffff !important;
    width: 100%;
    flex-shrink: 0;
    max-width: 230px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e7eb !important;
    border-radius: 0.5rem;
  }

  :global(.verbaflo-property-card-image-container) {
    max-height: 126px;
    overflow: hidden;
  }

  :global(.verbaflo-property-card-image) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  :global(.verbaflo-property-card-details) {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    height: 100%;
  }

  :global(.verbaflo-property-card-title) {
    font-weight: 500;
    margin: 0;
  }

  :global(.verbaflo-property-card-info-row) {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin: 0;
  }

  :global(.verbaflo-property-card-info-row--pt) {
    padding-top: 0.5rem;
  }

  :global(.verbaflo-property-label) {
    font-weight: 500;
  }

  :global(.verbaflo-property-value) {
    font-weight: 300;
    margin: 0;
  }

  :global(.verbaflo-property-card-amenities) {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
  }

  :global(.verbaflo-property-card-button) {
    color: #ffffff !important;
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 0.25rem;
    margin-top: auto !important;
    border: none;
  }
</style>
