<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.032 1.66667C5.48203 1.66667 1.7737 5.375 1.7737 9.925C1.7737 11.3833 2.15703 12.8 2.8737 14.05L1.70703 18.3333L6.08203 17.1833C7.29036 17.8417 8.6487 18.1917 10.032 18.1917C14.582 18.1917 18.2904 14.4833 18.2904 9.93334C18.2904 7.725 17.432 5.65 15.8737 4.09167C15.1096 3.32009 14.1996 2.70831 13.1966 2.29197C12.1937 1.87564 11.1179 1.66307 10.032 1.66667ZM10.0404 3.05834C11.8737 3.05834 13.5904 3.775 14.8904 5.075C15.5282 5.71294 16.0339 6.47043 16.3785 7.3041C16.7231 8.13777 16.8999 9.03125 16.8987 9.93334C16.8987 13.7167 13.8154 16.7917 10.032 16.7917C8.7987 16.7917 7.59036 16.4667 6.54036 15.8333L6.29036 15.6917L3.69036 16.375L4.38203 13.8417L4.21536 13.575C3.52749 12.482 3.16342 11.2164 3.16536 9.925C3.1737 6.14167 6.2487 3.05834 10.0404 3.05834ZM7.10703 6.10834C6.9737 6.10834 6.7487 6.15834 6.55703 6.36667C6.3737 6.575 5.83203 7.08334 5.83203 8.09167C5.83203 9.10834 6.5737 10.0833 6.66536 10.225C6.78203 10.3667 8.13203 12.45 10.207 13.3333C10.6987 13.5583 11.082 13.6833 11.382 13.775C11.8737 13.9333 12.3237 13.9083 12.682 13.8583C13.082 13.8 13.8987 13.3583 14.0737 12.875C14.2487 12.3917 14.2487 11.9833 14.1987 11.8917C14.1404 11.8083 14.007 11.7583 13.7987 11.6667C13.5904 11.55 12.5737 11.05 12.3904 10.9833C12.1987 10.9167 12.082 10.8833 11.9237 11.0833C11.7904 11.2917 11.3904 11.7583 11.2737 11.8917C11.1487 12.0333 11.032 12.05 10.832 11.95C10.6154 11.8417 9.9487 11.625 9.16536 10.925C8.5487 10.375 8.14036 9.70001 8.01536 9.49167C7.91536 9.29167 8.00703 9.16667 8.10703 9.07501C8.1987 8.98334 8.33203 8.83334 8.41537 8.70834C8.5237 8.59167 8.55703 8.5 8.6237 8.36667C8.69036 8.225 8.65703 8.10834 8.60703 8.00834C8.55703 7.91667 8.14037 6.88334 7.96536 6.475C7.7987 6.075 7.63203 6.125 7.4987 6.11667C7.38203 6.11667 7.2487 6.10834 7.10703 6.10834Z"
    fill="currentColor"
  />
</svg>
