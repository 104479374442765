<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.3312 14.0994V16.5994C18.3322 16.8315 18.2846 17.0612 18.1917 17.2739C18.0987 17.4865 17.9623 17.6774 17.7913 17.8343C17.6203 17.9912 17.4184 18.1107 17.1985 18.185C16.9787 18.2594 16.7457 18.287 16.5146 18.2661C13.9503 17.9875 11.4871 17.1112 9.32291 15.7078C7.30943 14.4283 5.60236 12.7212 4.32291 10.7078C2.91456 8.53377 2.03811 6.05859 1.76458 3.48276C1.74375 3.25232 1.77114 3.02006 1.84499 2.80078C1.91885 2.5815 2.03755 2.38 2.19355 2.20911C2.34954 2.03822 2.53941 1.90169 2.75107 1.8082C2.96272 1.71471 3.19153 1.66631 3.42291 1.6661H5.92291C6.32733 1.66212 6.7194 1.80533 7.02604 2.06904C7.33269 2.33275 7.53297 2.69897 7.58958 3.09943C7.6951 3.89949 7.89078 4.68504 8.17291 5.4411C8.28503 5.73937 8.3093 6.06353 8.24283 6.37516C8.17637 6.6868 8.02196 6.97286 7.79791 7.19943L6.73958 8.25776C7.92587 10.3441 9.65329 12.0715 11.7396 13.2578L12.7979 12.1994C13.0245 11.9754 13.3105 11.821 13.6222 11.7545C13.9338 11.688 14.258 11.7123 14.5562 11.8244C15.3123 12.1066 16.0979 12.3022 16.8979 12.4078C17.3027 12.4649 17.6724 12.6688 17.9367 12.9807C18.201 13.2926 18.3414 13.6907 18.3312 14.0994Z"
    fill="#5B93FF"
  />
  <path
    d="M15.7734 12.9918C16.329 12.8529 17.8568 13.8249 17.4401 15.0742"
    stroke="white"
    stroke-width="0.6"
    stroke-linecap="round"
  />
</svg>
