<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.3926 7.99862C12.3926 9.08012 14.0451 10.0955 13.5559 10.9747C12.3926 13.0658 10.5802 14.0639 8.34372 14.0639C6.31475 14.0639 5.67328 12.5417 4.59202 10.9747C3.91312 9.99087 2.36328 9.29063 2.36328 7.99862C2.36328 5.92109 3.87609 5.39981 5.44636 4.30672C6.40796 3.63734 7.08929 1.93335 8.34372 1.93335C11.6466 1.93335 12.3926 4.64886 12.3926 7.99862Z"
    fill="url(#paint0_radial_7616_19901)"
  />
  <path
    d="M12.3926 7.99862C12.3926 9.08012 14.0451 10.0955 13.5559 10.9747C12.3926 13.0658 10.5802 14.0639 8.34372 14.0639C6.31475 14.0639 5.67328 12.5417 4.59202 10.9747C3.91312 9.99087 2.36328 9.29063 2.36328 7.99862C2.36328 5.92109 3.87609 5.39981 5.44636 4.30672C6.40796 3.63734 7.08929 1.93335 8.34372 1.93335C11.6466 1.93335 12.3926 4.64886 12.3926 7.99862Z"
    fill="url(#paint1_radial_7616_19901)"
  />
  <path
    d="M12.3926 7.99862C12.3926 9.08012 14.0451 10.0955 13.5559 10.9747C12.3926 13.0658 10.5802 14.0639 8.34372 14.0639C6.31475 14.0639 5.67328 12.5417 4.59202 10.9747C3.91312 9.99087 2.36328 9.29063 2.36328 7.99862C2.36328 5.92109 3.87609 5.39981 5.44636 4.30672C6.40796 3.63734 7.08929 1.93335 8.34372 1.93335C11.6466 1.93335 12.3926 4.64886 12.3926 7.99862Z"
    fill="url(#paint2_radial_7616_19901)"
  />
  <g style="mix-blend-mode:plus-lighter" opacity="0.4">
    <path
      d="M4.09544 10.0173C3.61202 9.0533 1.68524 8.90797 1.72823 7.89938C1.83047 5.50065 2.99977 3.77781 4.99329 2.74955C6.8018 1.81672 8.05394 2.87855 9.71815 3.77817C10.7631 4.34302 12.4575 4.25463 13.035 5.40625C13.9636 7.25805 12.8482 8.41822 11.9371 10.1145C11.3792 11.1532 11.5336 12.9853 10.4155 13.562C7.47143 15.0806 5.59273 13.0031 4.09544 10.0173Z"
      fill="url(#paint3_radial_7616_19901)"
    />
    <path
      d="M4.09544 10.0173C3.61202 9.0533 1.68524 8.90797 1.72823 7.89938C1.83047 5.50065 2.99977 3.77781 4.99329 2.74955C6.8018 1.81672 8.05394 2.87855 9.71815 3.77817C10.7631 4.34302 12.4575 4.25463 13.035 5.40625C13.9636 7.25805 12.8482 8.41822 11.9371 10.1145C11.3792 11.1532 11.5336 12.9853 10.4155 13.562C7.47143 15.0806 5.59273 13.0031 4.09544 10.0173Z"
      fill="url(#paint4_radial_7616_19901)"
    />
    <path
      d="M4.09544 10.0173C3.61202 9.0533 1.68524 8.90797 1.72823 7.89938C1.83047 5.50065 2.99977 3.77781 4.99329 2.74955C6.8018 1.81672 8.05394 2.87855 9.71815 3.77817C10.7631 4.34302 12.4575 4.25463 13.035 5.40625C13.9636 7.25805 12.8482 8.41822 11.9371 10.1145C11.3792 11.1532 11.5336 12.9853 10.4155 13.562C7.47143 15.0806 5.59273 13.0031 4.09544 10.0173Z"
      fill="url(#paint5_radial_7616_19901)"
    />
  </g>
  <path
    d="M10.135 11.741C9.8758 11.8928 9.68616 12.1393 9.53221 12.4074C9.40666 12.626 9.29904 12.8699 9.19683 13.1016C9.17406 13.1532 9.15155 13.2042 9.12918 13.2541C9.0037 13.5345 8.88259 13.782 8.73675 13.9603C8.59436 14.1345 8.43805 14.2313 8.23854 14.2333C5.91915 14.2566 4.20246 13.1901 3.10619 11.2644C2.61836 10.4074 2.61033 9.66753 2.81975 8.92606C2.92553 8.55156 3.08718 8.17574 3.27433 7.78195C3.34978 7.62318 3.43043 7.45942 3.51301 7.29176C3.63234 7.04945 3.7557 6.79899 3.87317 6.54368C4.00442 6.25842 4.08962 5.93619 4.16117 5.61038C4.19055 5.47658 4.21727 5.34419 4.2438 5.21274C4.28325 5.01728 4.32228 4.82392 4.36901 4.63114C4.5241 3.9913 4.74537 3.45019 5.24777 3.15601C6.09955 2.65726 6.80512 2.66513 7.51179 2.89647C7.86965 3.01362 8.2286 3.18853 8.6095 3.38893C8.72186 3.44804 8.83639 3.50952 8.95323 3.57224C9.22948 3.72054 9.5186 3.87573 9.82223 4.02285C10.1002 4.15752 10.4226 4.23974 10.7514 4.30542C10.9019 4.33549 11.0558 4.36246 11.2086 4.38923C11.2232 4.39178 11.2377 4.39432 11.2522 4.39687C11.4199 4.42629 11.5861 4.45586 11.7485 4.4899C12.4058 4.62762 12.9589 4.83131 13.2424 5.32922C13.6466 6.03933 13.8265 6.68751 13.8314 7.28384C13.8363 7.8796 13.6667 8.43228 13.3583 8.9523C12.7384 9.99765 11.5614 10.9059 10.135 11.741Z"
    stroke="url(#paint6_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <path
    d="M10.135 11.741C9.8758 11.8928 9.68616 12.1393 9.53221 12.4074C9.40666 12.626 9.29904 12.8699 9.19683 13.1016C9.17406 13.1532 9.15155 13.2042 9.12918 13.2541C9.0037 13.5345 8.88259 13.782 8.73675 13.9603C8.59436 14.1345 8.43805 14.2313 8.23854 14.2333C5.91915 14.2566 4.20246 13.1901 3.10619 11.2644C2.61836 10.4074 2.61033 9.66753 2.81975 8.92606C2.92553 8.55156 3.08718 8.17574 3.27433 7.78195C3.34978 7.62318 3.43043 7.45942 3.51301 7.29176C3.63234 7.04945 3.7557 6.79899 3.87317 6.54368C4.00442 6.25842 4.08962 5.93619 4.16117 5.61038C4.19055 5.47658 4.21727 5.34419 4.2438 5.21274C4.28325 5.01728 4.32228 4.82392 4.36901 4.63114C4.5241 3.9913 4.74537 3.45019 5.24777 3.15601C6.09955 2.65726 6.80512 2.66513 7.51179 2.89647C7.86965 3.01362 8.2286 3.18853 8.6095 3.38893C8.72186 3.44804 8.83639 3.50952 8.95323 3.57224C9.22948 3.72054 9.5186 3.87573 9.82223 4.02285C10.1002 4.15752 10.4226 4.23974 10.7514 4.30542C10.9019 4.33549 11.0558 4.36246 11.2086 4.38923C11.2232 4.39178 11.2377 4.39432 11.2522 4.39687C11.4199 4.42629 11.5861 4.45586 11.7485 4.4899C12.4058 4.62762 12.9589 4.83131 13.2424 5.32922C13.6466 6.03933 13.8265 6.68751 13.8314 7.28384C13.8363 7.8796 13.6667 8.43228 13.3583 8.9523C12.7384 9.99765 11.5614 10.9059 10.135 11.741Z"
    stroke="url(#paint7_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <path
    d="M10.135 11.741C9.8758 11.8928 9.68616 12.1393 9.53221 12.4074C9.40666 12.626 9.29904 12.8699 9.19683 13.1016C9.17406 13.1532 9.15155 13.2042 9.12918 13.2541C9.0037 13.5345 8.88259 13.782 8.73675 13.9603C8.59436 14.1345 8.43805 14.2313 8.23854 14.2333C5.91915 14.2566 4.20246 13.1901 3.10619 11.2644C2.61836 10.4074 2.61033 9.66753 2.81975 8.92606C2.92553 8.55156 3.08718 8.17574 3.27433 7.78195C3.34978 7.62318 3.43043 7.45942 3.51301 7.29176C3.63234 7.04945 3.7557 6.79899 3.87317 6.54368C4.00442 6.25842 4.08962 5.93619 4.16117 5.61038C4.19055 5.47658 4.21727 5.34419 4.2438 5.21274C4.28325 5.01728 4.32228 4.82392 4.36901 4.63114C4.5241 3.9913 4.74537 3.45019 5.24777 3.15601C6.09955 2.65726 6.80512 2.66513 7.51179 2.89647C7.86965 3.01362 8.2286 3.18853 8.6095 3.38893C8.72186 3.44804 8.83639 3.50952 8.95323 3.57224C9.22948 3.72054 9.5186 3.87573 9.82223 4.02285C10.1002 4.15752 10.4226 4.23974 10.7514 4.30542C10.9019 4.33549 11.0558 4.36246 11.2086 4.38923C11.2232 4.39178 11.2377 4.39432 11.2522 4.39687C11.4199 4.42629 11.5861 4.45586 11.7485 4.4899C12.4058 4.62762 12.9589 4.83131 13.2424 5.32922C13.6466 6.03933 13.8265 6.68751 13.8314 7.28384C13.8363 7.8796 13.6667 8.43228 13.3583 8.9523C12.7384 9.99765 11.5614 10.9059 10.135 11.741Z"
    stroke="url(#paint8_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <path
    d="M10.1103 4.29969C9.85478 4.15007 9.66797 3.90717 9.51641 3.64329C9.3928 3.42806 9.28685 3.18793 9.18627 2.95997C9.16387 2.9092 9.14174 2.85904 9.11974 2.80989C8.99626 2.53405 8.87718 2.29072 8.73388 2.11546C8.59402 1.9444 8.44074 1.84956 8.24522 1.8476C5.96375 1.82468 4.27522 2.87374 3.19687 4.76799C2.71716 5.61065 2.70927 6.33812 2.91521 7.06725C3.01924 7.43557 3.17824 7.80525 3.36238 8.1927C3.43659 8.34885 3.51594 8.50997 3.59719 8.67494C3.71463 8.91341 3.83604 9.15992 3.95163 9.41116C4.08088 9.69206 4.16475 10.0093 4.23517 10.3299C4.2641 10.4617 4.2904 10.592 4.3165 10.7213C4.35531 10.9136 4.39369 11.1038 4.43967 11.2934C4.59226 11.923 4.80982 12.4546 5.30338 12.7436C6.14095 13.2341 6.83461 13.2263 7.52948 12.9988C7.88144 12.8836 8.23451 12.7116 8.60929 12.5144C8.71982 12.4563 8.8325 12.3958 8.94746 12.3341C9.2193 12.1881 9.50385 12.0354 9.80266 11.8906C10.0764 11.758 10.3938 11.677 10.7174 11.6124C10.8656 11.5828 11.017 11.5563 11.1674 11.5299C11.1817 11.5274 11.196 11.5249 11.2103 11.5224C11.3753 11.4935 11.5388 11.4644 11.6986 11.4309C12.3453 11.2954 12.8887 11.0951 13.1671 10.606C13.5648 9.9075 13.7417 9.27004 13.7465 8.68369C13.7513 8.0979 13.5845 7.55444 13.2812 7.04304C12.6716 6.01491 11.5138 5.12147 10.1103 4.29969Z"
    stroke="url(#paint9_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <path
    d="M10.1103 4.29969C9.85478 4.15007 9.66797 3.90717 9.51641 3.64329C9.3928 3.42806 9.28685 3.18793 9.18627 2.95997C9.16387 2.9092 9.14174 2.85904 9.11974 2.80989C8.99626 2.53405 8.87718 2.29072 8.73388 2.11546C8.59402 1.9444 8.44074 1.84956 8.24522 1.8476C5.96375 1.82468 4.27522 2.87374 3.19687 4.76799C2.71716 5.61065 2.70927 6.33812 2.91521 7.06725C3.01924 7.43557 3.17824 7.80525 3.36238 8.1927C3.43659 8.34885 3.51594 8.50997 3.59719 8.67494C3.71463 8.91341 3.83604 9.15992 3.95163 9.41116C4.08088 9.69206 4.16475 10.0093 4.23517 10.3299C4.2641 10.4617 4.2904 10.592 4.3165 10.7213C4.35531 10.9136 4.39369 11.1038 4.43967 11.2934C4.59226 11.923 4.80982 12.4546 5.30338 12.7436C6.14095 13.2341 6.83461 13.2263 7.52948 12.9988C7.88144 12.8836 8.23451 12.7116 8.60929 12.5144C8.71982 12.4563 8.8325 12.3958 8.94746 12.3341C9.2193 12.1881 9.50385 12.0354 9.80266 11.8906C10.0764 11.758 10.3938 11.677 10.7174 11.6124C10.8656 11.5828 11.017 11.5563 11.1674 11.5299C11.1817 11.5274 11.196 11.5249 11.2103 11.5224C11.3753 11.4935 11.5388 11.4644 11.6986 11.4309C12.3453 11.2954 12.8887 11.0951 13.1671 10.606C13.5648 9.9075 13.7417 9.27004 13.7465 8.68369C13.7513 8.0979 13.5845 7.55444 13.2812 7.04304C12.6716 6.01491 11.5138 5.12147 10.1103 4.29969Z"
    stroke="url(#paint10_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <path
    d="M10.1103 4.29969C9.85478 4.15007 9.66797 3.90717 9.51641 3.64329C9.3928 3.42806 9.28685 3.18793 9.18627 2.95997C9.16387 2.9092 9.14174 2.85904 9.11974 2.80989C8.99626 2.53405 8.87718 2.29072 8.73388 2.11546C8.59402 1.9444 8.44074 1.84956 8.24522 1.8476C5.96375 1.82468 4.27522 2.87374 3.19687 4.76799C2.71716 5.61065 2.70927 6.33812 2.91521 7.06725C3.01924 7.43557 3.17824 7.80525 3.36238 8.1927C3.43659 8.34885 3.51594 8.50997 3.59719 8.67494C3.71463 8.91341 3.83604 9.15992 3.95163 9.41116C4.08088 9.69206 4.16475 10.0093 4.23517 10.3299C4.2641 10.4617 4.2904 10.592 4.3165 10.7213C4.35531 10.9136 4.39369 11.1038 4.43967 11.2934C4.59226 11.923 4.80982 12.4546 5.30338 12.7436C6.14095 13.2341 6.83461 13.2263 7.52948 12.9988C7.88144 12.8836 8.23451 12.7116 8.60929 12.5144C8.71982 12.4563 8.8325 12.3958 8.94746 12.3341C9.2193 12.1881 9.50385 12.0354 9.80266 11.8906C10.0764 11.758 10.3938 11.677 10.7174 11.6124C10.8656 11.5828 11.017 11.5563 11.1674 11.5299C11.1817 11.5274 11.196 11.5249 11.2103 11.5224C11.3753 11.4935 11.5388 11.4644 11.6986 11.4309C12.3453 11.2954 12.8887 11.0951 13.1671 10.606C13.5648 9.9075 13.7417 9.27004 13.7465 8.68369C13.7513 8.0979 13.5845 7.55444 13.2812 7.04304C12.6716 6.01491 11.5138 5.12147 10.1103 4.29969Z"
    stroke="url(#paint11_radial_7616_19901)"
    stroke-width="0.257525"
  />
  <defs>
    <radialGradient
      id="paint0_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(8.00941 1.93335) rotate(90.0215) scale(12.1305 11.2832)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint1_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(2.36328 8.03629) scale(11.2923 10.8035)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint2_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(7.11791 0.953865) rotate(78.129) scale(17.5156 16.3117)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint3_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(10.7134 13.4083) rotate(-116.611) scale(12.0959 11.3155)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint4_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(13.0182 5.37267) rotate(152.715) scale(11.3254 10.772)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint5_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(11.9459 13.8715) rotate(-128.598) scale(17.4295 16.3923)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint6_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(13.1863 4.97053) rotate(149.671) scale(12.0035 11.4026)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint7_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(5.1519 3.06295) rotate(60.3482) scale(11.4126 10.6896)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint8_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(13.577 3.69777) rotate(137.564) scale(17.3914 16.4282)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint9_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(13.1138 10.96) rotate(-149.671) scale(11.8112 11.22)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint10_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(5.20803 12.837) rotate(-60.3482) scale(11.2298 10.5184)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint11_radial_7616_19901"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(13.4982 12.2123) rotate(-137.564) scale(17.1128 16.1651)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
  </defs>
</svg>
