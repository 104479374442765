<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.8516 2.96875H2.14844C1.28549 2.96875 0.585938 3.66831 0.585938 4.53125V15.4688C0.585938 16.3317 1.28549 17.0312 2.14844 17.0312H17.8516C18.7145 17.0312 19.4141 16.3317 19.4141 15.4688V4.53125C19.4141 3.66831 18.7145 2.96875 17.8516 2.96875Z"
    fill="#FFCF66"
  />
  <path
    d="M17.8516 2.38281H2.14844C1.57883 2.38343 1.03272 2.60998 0.629948 3.01276C0.227172 3.41554 0.000620379 3.96164 0 4.53125V15.4688C0.000620379 16.0384 0.227172 16.5845 0.629948 16.9872C1.03272 17.39 1.57883 17.6166 2.14844 17.6172H17.8516C18.4212 17.6166 18.9673 17.39 19.3701 16.9872C19.7728 16.5845 19.9994 16.0384 20 15.4688V4.53125C19.9994 3.96164 19.7728 3.41554 19.3701 3.01276C18.9673 2.60998 18.4212 2.38343 17.8516 2.38281ZM18.8281 15.4688C18.8281 15.7278 18.7252 15.9761 18.5421 16.1593C18.359 16.3424 18.1106 16.4453 17.8516 16.4453H2.14844C1.88944 16.4453 1.64104 16.3424 1.4579 16.1593C1.27476 15.9761 1.17188 15.7278 1.17188 15.4688V4.53125C1.17188 4.27225 1.27476 4.02386 1.4579 3.84072C1.64104 3.65757 1.88944 3.55469 2.14844 3.55469H17.8516C18.1106 3.55469 18.359 3.65757 18.5421 3.84072C18.7252 4.02386 18.8281 4.27225 18.8281 4.53125V15.4688Z"
    fill="#FFCF66"
  />
  <path
    d="M16.6715 4.85001L11.0793 9.19962C10.7707 9.43966 10.391 9.56998 10 9.56998C9.60907 9.56998 9.22929 9.43966 8.92071 9.19962L3.32853 4.85001C3.26779 4.80277 3.19834 4.76795 3.12415 4.74754C3.04996 4.72714 2.97247 4.72154 2.89612 4.73108C2.81976 4.74062 2.74604 4.76511 2.67914 4.80314C2.61225 4.84117 2.55351 4.89201 2.50626 4.95275C2.45902 5.01348 2.4242 5.08293 2.40379 5.15712C2.38339 5.23132 2.37779 5.3088 2.38733 5.38516C2.39687 5.46151 2.42136 5.53524 2.45939 5.60213C2.49742 5.66902 2.54826 5.72777 2.609 5.77501L8.20314 10.1246C8.71742 10.5247 9.3504 10.7419 10.002 10.7419C10.6535 10.7419 11.2865 10.5247 11.8008 10.1246L17.391 5.77501C17.4518 5.72777 17.5026 5.66902 17.5406 5.60213C17.5787 5.53524 17.6032 5.46151 17.6127 5.38516C17.6222 5.3088 17.6166 5.23132 17.5962 5.15712C17.5758 5.08293 17.541 5.01348 17.4938 4.95275C17.4465 4.89201 17.3878 4.84117 17.3209 4.80314C17.254 4.76511 17.1803 4.74062 17.1039 4.73108C17.0276 4.72154 16.9501 4.72714 16.8759 4.74754C16.8017 4.76795 16.7322 4.80277 16.6715 4.85001Z"
    fill="white"
  />
</svg>
