<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.3351 14.1V16.6C18.3361 16.8321 18.2886 17.0618 18.1956 17.2745C18.1026 17.4871 17.9662 17.678 17.7952 17.8349C17.6242 17.9918 17.4223 18.1113 17.2024 18.1856C16.9826 18.26 16.7496 18.2876 16.5185 18.2667C13.9542 17.9881 11.491 17.1118 9.32682 15.7084C7.31334 14.4289 5.60626 12.7219 4.32682 10.7084C2.91846 8.53438 2.04202 6.0592 1.76848 3.48337C1.74766 3.25293 1.77505 3.02067 1.8489 2.80139C1.92275 2.58211 2.04146 2.38061 2.19745 2.20972C2.35345 2.03883 2.54332 1.9023 2.75498 1.80881C2.96663 1.71532 3.19543 1.66692 3.42682 1.66671H5.92682C6.33124 1.66273 6.72331 1.80594 7.02995 2.06965C7.33659 2.33336 7.53688 2.69958 7.59348 3.10004C7.699 3.9001 7.89469 4.68565 8.17682 5.44171C8.28894 5.73998 8.3132 6.06414 8.24674 6.37577C8.18028 6.68741 8.02587 6.97347 7.80182 7.20004L6.74348 8.25837C7.92978 10.3447 9.65719 12.0721 11.7435 13.2584L12.8018 12.2C13.0284 11.976 13.3144 11.8216 13.6261 11.7551C13.9377 11.6887 14.2619 11.7129 14.5601 11.825C15.3162 12.1072 16.1018 12.3029 16.9018 12.4084C17.3066 12.4655 17.6763 12.6694 17.9406 12.9813C18.2049 13.2932 18.3453 13.6914 18.3351 14.1Z"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
