<script>
  import anime from "animejs/lib/anime.es.js";
  import { onMount } from "svelte";

  onMount(() => {
    anime({
      targets: "#svg .animate_paths",
      translateX: () => anime.random(-20, 20), // Random movement within a range for each loop
      translateY: () => anime.random(-20, 20), // Random movement within a range for each loop
      scale: () => anime.random(0.75, 1), // Slight scaling effect centered at origin
      rotate: () => anime.random(-5, 5), // Random small rotation
      easing: "easeInOutSine",
      duration: () => anime.random(200, 2500), // Random duration for variation
      direction: "alternate",
      loop: true, // Set loop to false, we'll restart animation manually
      transformOrigin: "50% 50%", // Ensure scaling is relative to the center of each path
      delay: (el, i) => i * 150, // Staggered start for each path
    });
  });
</script>

<svg
  width="45"
  height="60"
  viewBox="0 0 256 256"
  fill="none"
  id="svg"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    width="256"
    height="256"
    transform="translate(0.983154)"
    fill="#14161B"
  />
  <path
    class="animate_paths"
    d="M186.402 127.999C186.402 142.154 208.03 155.444 201.628 166.952C186.402 194.322 162.681 207.385 133.407 207.385C106.851 207.385 98.4549 187.462 84.3025 166.952C75.4167 154.075 55.1313 144.91 55.1313 127.999C55.1313 100.807 74.9321 93.9839 95.4848 79.6768C108.071 70.9155 116.989 48.6125 133.407 48.6125C176.638 48.6125 186.402 84.155 186.402 127.999Z"
    fill="url(#paint0_radial_597_917)"
  />
  <path
    class="animate_paths"
    d="M186.402 127.999C186.402 142.154 208.03 155.444 201.628 166.952C186.402 194.322 162.681 207.385 133.407 207.385C106.851 207.385 98.4549 187.462 84.3025 166.952C75.4167 154.075 55.1313 144.91 55.1313 127.999C55.1313 100.807 74.9321 93.9839 95.4848 79.6768C108.071 70.9155 116.989 48.6125 133.407 48.6125C176.638 48.6125 186.402 84.155 186.402 127.999Z"
    fill="url(#paint1_radial_597_917)"
  />
  <path
    class="animate_paths"
    d="M186.402 127.999C186.402 142.154 208.03 155.444 201.628 166.952C186.402 194.322 162.681 207.385 133.407 207.385C106.851 207.385 98.4549 187.462 84.3025 166.952C75.4167 154.075 55.1313 144.91 55.1313 127.999C55.1313 100.807 74.9321 93.9839 95.4848 79.6768C108.071 70.9155 116.989 48.6125 133.407 48.6125C176.638 48.6125 186.402 84.155 186.402 127.999Z"
    fill="url(#paint2_radial_597_917)"
  />
  <g style="mix-blend-mode:plus-lighter" opacity="0.4">
    <path
      class="animate_paths"
      d="M77.7779 154.404C71.4506 141.786 46.2316 139.884 46.7943 126.683C48.1324 95.287 63.4371 72.7372 89.5296 59.2787C113.201 47.0691 129.589 60.9672 151.372 72.742C165.048 80.1351 187.226 78.9782 194.785 94.0514C206.939 118.289 192.34 133.474 180.415 155.676C173.113 169.272 175.133 193.251 160.499 200.8C121.965 220.675 97.3755 193.484 77.7779 154.404Z"
      fill="url(#paint3_radial_597_917)"
    />
    <path
      class="animate_paths"
      d="M77.7779 154.404C71.4506 141.786 46.2316 139.884 46.7943 126.683C48.1324 95.287 63.4371 72.7372 89.5296 59.2787C113.201 47.0691 129.589 60.9672 151.372 72.742C165.048 80.1351 187.226 78.9782 194.785 94.0514C206.939 118.289 192.34 133.474 180.415 155.676C173.113 169.272 175.133 193.251 160.499 200.8C121.965 220.675 97.3755 193.484 77.7779 154.404Z"
      fill="url(#paint4_radial_597_917)"
    />
    <path
      class="animate_paths"
      d="M77.7779 154.404C71.4506 141.786 46.2316 139.884 46.7943 126.683C48.1324 95.287 63.4371 72.7372 89.5296 59.2787C113.201 47.0691 129.589 60.9672 151.372 72.742C165.048 80.1351 187.226 78.9782 194.785 94.0514C206.939 118.289 192.34 133.474 180.415 155.676C173.113 169.272 175.133 193.251 160.499 200.8C121.965 220.675 97.3755 193.484 77.7779 154.404Z"
      fill="url(#paint5_radial_597_917)"
    />
  </g>
  <path
    class="animate_paths"
    d="M198.309 92.5984C203.66 101.999 206.072 110.633 206.138 118.624C206.203 126.611 203.926 134.007 199.817 140.936C191.58 154.828 176.002 166.811 157.306 177.758C154.112 179.629 151.727 182.697 149.745 186.148C148.128 188.963 146.744 192.1 145.407 195.13C145.107 195.81 144.809 196.485 144.511 197.149C142.872 200.811 141.244 204.157 139.248 206.597C137.274 209.012 134.998 210.472 132.042 210.502C101.349 210.81 78.5885 196.667 64.086 171.191C57.5652 159.737 57.4543 149.796 60.2528 139.888C61.6585 134.911 63.8008 129.936 66.2568 124.769C67.2576 122.663 68.317 120.512 69.3983 118.317C70.953 115.16 72.5531 111.912 74.0899 108.572C75.7653 104.93 76.8639 100.791 77.7969 96.5425C78.1738 94.8262 78.5215 93.1043 78.8685 91.386C79.3891 88.808 79.9081 86.2379 80.5217 83.7062C82.5598 75.2982 85.5279 67.8785 92.4479 63.8266C103.843 57.1543 113.346 57.2535 122.807 60.3508C127.565 61.9085 132.318 64.2271 137.311 66.8542C138.795 67.6348 140.301 68.4435 141.835 69.2666C145.444 71.204 149.201 73.221 153.158 75.1381C156.692 76.8505 160.822 77.9094 165.107 78.7653C167.067 79.1568 169.072 79.508 171.074 79.8587C171.263 79.892 171.453 79.9252 171.642 79.9584C173.837 80.3434 176.025 80.7327 178.167 81.1815C186.781 82.9865 194.388 85.711 198.309 92.5984Z"
    stroke="url(#paint6_radial_597_917)"
    stroke-width="1.57002"
  />
  <path
    class="animate_paths"
    d="M198.309 92.5984C203.66 101.999 206.072 110.633 206.138 118.624C206.203 126.611 203.926 134.007 199.817 140.936C191.58 154.828 176.002 166.811 157.306 177.758C154.112 179.629 151.727 182.697 149.745 186.148C148.128 188.963 146.744 192.1 145.407 195.13C145.107 195.81 144.809 196.485 144.511 197.149C142.872 200.811 141.244 204.157 139.248 206.597C137.274 209.012 134.998 210.472 132.042 210.502C101.349 210.81 78.5885 196.667 64.086 171.191C57.5652 159.737 57.4543 149.796 60.2528 139.888C61.6585 134.911 63.8008 129.936 66.2568 124.769C67.2576 122.663 68.317 120.512 69.3983 118.317C70.953 115.16 72.5531 111.912 74.0899 108.572C75.7653 104.93 76.8639 100.791 77.7969 96.5425C78.1738 94.8262 78.5215 93.1043 78.8685 91.386C79.3891 88.808 79.9081 86.2379 80.5217 83.7062C82.5598 75.2982 85.5279 67.8785 92.4479 63.8266C103.843 57.1543 113.346 57.2535 122.807 60.3508C127.565 61.9085 132.318 64.2271 137.311 66.8542C138.795 67.6348 140.301 68.4435 141.835 69.2666C145.444 71.204 149.201 73.221 153.158 75.1381C156.692 76.8505 160.822 77.9094 165.107 78.7653C167.067 79.1568 169.072 79.508 171.074 79.8587C171.263 79.892 171.453 79.9252 171.642 79.9584C173.837 80.3434 176.025 80.7327 178.167 81.1815C186.781 82.9865 194.388 85.711 198.309 92.5984Z"
    stroke="url(#paint7_radial_597_917)"
    stroke-width="1.57002"
  />
  <path
    class="animate_paths"
    d="M198.309 92.5984C203.66 101.999 206.072 110.633 206.138 118.624C206.203 126.611 203.926 134.007 199.817 140.936C191.58 154.828 176.002 166.811 157.306 177.758C154.112 179.629 151.727 182.697 149.745 186.148C148.128 188.963 146.744 192.1 145.407 195.13C145.107 195.81 144.809 196.485 144.511 197.149C142.872 200.811 141.244 204.157 139.248 206.597C137.274 209.012 134.998 210.472 132.042 210.502C101.349 210.81 78.5885 196.667 64.086 171.191C57.5652 159.737 57.4543 149.796 60.2528 139.888C61.6585 134.911 63.8008 129.936 66.2568 124.769C67.2576 122.663 68.317 120.512 69.3983 118.317C70.953 115.16 72.5531 111.912 74.0899 108.572C75.7653 104.93 76.8639 100.791 77.7969 96.5425C78.1738 94.8262 78.5215 93.1043 78.8685 91.386C79.3891 88.808 79.9081 86.2379 80.5217 83.7062C82.5598 75.2982 85.5279 67.8785 92.4479 63.8266C103.843 57.1543 113.346 57.2535 122.807 60.3508C127.565 61.9085 132.318 64.2271 137.311 66.8542C138.795 67.6348 140.301 68.4435 141.835 69.2666C145.444 71.204 149.201 73.221 153.158 75.1381C156.692 76.8505 160.822 77.9094 165.107 78.7653C167.067 79.1568 169.072 79.508 171.074 79.8587C171.263 79.892 171.453 79.9252 171.642 79.9584C173.837 80.3434 176.025 80.7327 178.167 81.1815C186.781 82.9865 194.388 85.711 198.309 92.5984Z"
    stroke="url(#paint8_radial_597_917)"
    stroke-width="1.57002"
  />
  <path
    class="animate_paths"
    d="M197.323 162.576C202.588 153.328 204.96 144.834 205.024 136.974C205.089 129.118 202.849 121.842 198.806 115.025C190.703 101.36 175.378 89.5695 156.981 78.7978C153.835 76.9555 151.487 73.9347 149.536 70.538C147.945 67.7674 146.583 64.6799 145.267 61.6981C144.972 61.0291 144.679 60.3654 144.386 59.7118C142.773 56.1085 141.172 52.8175 139.209 50.4176C137.268 48.0433 135.032 46.6098 132.128 46.5806C101.932 46.2772 79.5393 60.1913 65.2713 85.2548C58.8568 96.5225 58.7477 106.301 61.5006 116.047C62.8835 120.944 64.9911 125.837 67.4077 130.922C68.3923 132.994 69.4347 135.11 70.4987 137.271C72.0286 140.377 73.6033 143.573 75.1154 146.86C76.7646 150.444 77.8458 154.518 78.7639 158.699C79.1349 160.388 79.4771 162.083 79.8185 163.774C80.3307 166.31 80.8412 168.838 81.445 171.329C83.4504 179.602 86.3699 186.898 93.1743 190.883C104.383 197.446 113.73 197.348 123.037 194.302C127.718 192.769 132.394 190.488 137.307 187.903C138.767 187.135 140.249 186.34 141.758 185.53C145.309 183.623 149.007 181.638 152.9 179.752C156.38 178.066 160.444 177.024 164.661 176.182C166.59 175.797 168.562 175.451 170.532 175.106C170.718 175.073 170.905 175.04 171.092 175.008C173.251 174.629 175.404 174.246 177.511 173.804C185.987 172.028 193.468 169.348 197.323 162.576Z"
    stroke="url(#paint9_radial_597_917)"
    stroke-width="1.57002"
  />
  <path
    class="animate_paths"
    d="M197.323 162.576C202.588 153.328 204.96 144.834 205.024 136.974C205.089 129.118 202.849 121.842 198.806 115.025C190.703 101.36 175.378 89.5695 156.981 78.7978C153.835 76.9555 151.487 73.9347 149.536 70.538C147.945 67.7674 146.583 64.6799 145.267 61.6981C144.972 61.0291 144.679 60.3654 144.386 59.7118C142.773 56.1085 141.172 52.8175 139.209 50.4176C137.268 48.0433 135.032 46.6098 132.128 46.5806C101.932 46.2772 79.5393 60.1913 65.2713 85.2548C58.8568 96.5225 58.7477 106.301 61.5006 116.047C62.8835 120.944 64.9911 125.837 67.4077 130.922C68.3923 132.994 69.4347 135.11 70.4987 137.271C72.0286 140.377 73.6033 143.573 75.1154 146.86C76.7646 150.444 77.8458 154.518 78.7639 158.699C79.1349 160.388 79.4771 162.083 79.8185 163.774C80.3307 166.31 80.8412 168.838 81.445 171.329C83.4504 179.602 86.3699 186.898 93.1743 190.883C104.383 197.446 113.73 197.348 123.037 194.302C127.718 192.769 132.394 190.488 137.307 187.903C138.767 187.135 140.249 186.34 141.758 185.53C145.309 183.623 149.007 181.638 152.9 179.752C156.38 178.066 160.444 177.024 164.661 176.182C166.59 175.797 168.562 175.451 170.532 175.106C170.718 175.073 170.905 175.04 171.092 175.008C173.251 174.629 175.404 174.246 177.511 173.804C185.987 172.028 193.468 169.348 197.323 162.576Z"
    stroke="url(#paint10_radial_597_917)"
    stroke-width="1.57002"
  />
  <path
    class="animate_paths"
    d="M197.323 162.576C202.588 153.328 204.96 144.834 205.024 136.974C205.089 129.118 202.849 121.842 198.806 115.025C190.703 101.36 175.378 89.5695 156.981 78.7978C153.835 76.9555 151.487 73.9347 149.536 70.538C147.945 67.7674 146.583 64.6799 145.267 61.6981C144.972 61.0291 144.679 60.3654 144.386 59.7118C142.773 56.1085 141.172 52.8175 139.209 50.4176C137.268 48.0433 135.032 46.6098 132.128 46.5806C101.932 46.2772 79.5393 60.1913 65.2713 85.2548C58.8568 96.5225 58.7477 106.301 61.5006 116.047C62.8835 120.944 64.9911 125.837 67.4077 130.922C68.3923 132.994 69.4347 135.11 70.4987 137.271C72.0286 140.377 73.6033 143.573 75.1154 146.86C76.7646 150.444 77.8458 154.518 78.7639 158.699C79.1349 160.388 79.4771 162.083 79.8185 163.774C80.3307 166.31 80.8412 168.838 81.445 171.329C83.4504 179.602 86.3699 186.898 93.1743 190.883C104.383 197.446 113.73 197.348 123.037 194.302C127.718 192.769 132.394 190.488 137.307 187.903C138.767 187.135 140.249 186.34 141.758 185.53C145.309 183.623 149.007 181.638 152.9 179.752C156.38 178.066 160.444 177.024 164.661 176.182C166.59 175.797 168.562 175.451 170.532 175.106C170.718 175.073 170.905 175.04 171.092 175.008C173.251 174.629 175.404 174.246 177.511 173.804C185.987 172.028 193.468 169.348 197.323 162.576Z"
    stroke="url(#paint11_radial_597_917)"
    stroke-width="1.57002"
  />
  <defs>
    <radialGradient
      id="paint0_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(129.032 48.6125) rotate(90.0215) scale(158.773 147.682)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint1_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(55.1314 128.492) scale(147.801 141.404)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint2_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(117.363 35.7924) rotate(78.129) scale(229.256 213.499)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint3_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(164.399 198.788) rotate(-116.611) scale(158.32 148.104)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint4_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(194.565 93.6119) rotate(152.715) scale(148.234 140.991)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint5_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(180.53 204.851) rotate(-128.598) scale(228.129 214.553)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint6_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(196.799 88.3586) rotate(149.671) scale(157.109 149.245)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint7_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(91.6385 63.3909) rotate(60.3482) scale(149.376 139.913)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint8_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(201.912 71.6999) rotate(137.564) scale(227.63 215.024)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
    <radialGradient
      id="paint9_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(195.847 166.755) rotate(-149.671) scale(154.593 146.855)"
    >
      <stop stop-color="#78DFFF" />
      <stop offset="0.603041" stop-color="#78DFFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint10_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(92.3716 191.322) rotate(-60.3482) scale(146.983 137.672)"
    >
      <stop stop-color="#C6BBFF" stop-opacity="0.711064" />
      <stop stop-color="#C6BBFF" />
      <stop offset="1" stop-color="#C6BBFF" stop-opacity="0" />
    </radialGradient>
    <radialGradient
      id="paint11_radial_597_917"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(200.879 183.146) rotate(-137.564) scale(223.984 211.58)"
    >
      <stop offset="0.404211" stop-color="#285BD5" stop-opacity="0" />
      <stop offset="0.894899" stop-color="#285BD5" />
    </radialGradient>
  </defs>
</svg>
