<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19 12.6667C19 13.1382 18.8127 13.5903 18.4793 13.9237C18.1459 14.2571 17.6937 14.4444 17.2222 14.4444H6.55556L3 18V3.77778C3 3.30628 3.1873 2.8541 3.5207 2.5207C3.8541 2.1873 4.30628 2 4.77778 2H17.2222C17.6937 2 18.1459 2.1873 18.4793 2.5207C18.8127 2.8541 19 3.30628 19 3.77778V12.6667Z"
    fill="currentColor"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
