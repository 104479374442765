<svg
  width="12"
  height="12"
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.5 9L7.5 6L4.5 3"
    stroke="#051133"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
