import { atom, onMount } from "nanostores";
import { connectSocket, socketManager } from "./chat";

export const isOpen = atom<boolean>(false);
export const isConfigLoaded = atom<boolean>(false);
export const hasOpened = atom<boolean>(false);

export const configLoaded = () => {
  isConfigLoaded.set(true);
};

export const openWidget = () => {
  isOpen.set(true);
};

export const closeWidget = () => {
  isOpen.set(false);
};

export const toggleWidget = () => {
  isConfigLoaded.set(true);
  isOpen.set(!isOpen.get());
  if (!hasOpened.get()) {
    hasOpened.set(true);
    sessionStorage.setItem("chatbot_opened", "true");
  }
};

onMount(isOpen, () => {
  console.log("Widget Mounted");
  if (!socketManager.get()) {
    connectSocket();
  }

  return () => {
    // Disabled mode
    console.log("Widget Unmounted");
    isOpen.set(false);
  };
});
