import { v4 as uuidv4 } from "uuid";
export function getSessionId(): string {
  const stored = localStorage.getItem("session_info");
  let session_id = "";
  let now = Date.now();

  if (stored) {
    const { id, timestamp } = JSON.parse(stored);
    const sessionAge = now - timestamp;
    const twelveHours = 12 * 60 * 60 * 1000;

    if (sessionAge < twelveHours) {
      session_id = id;
    } else {
      localStorage.removeItem("session_info");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_details_form_submitted");
      localStorage.removeItem("updateDataLayer");
      localStorage.removeItem("user_form_details");
    }
  }

  if (!session_id) {
    session_id = uuidv4();
    const sessionData = {
      id: session_id,
      timestamp: now,
    };
    localStorage.setItem("session_info", JSON.stringify(sessionData));
  }

  return session_id;
}
