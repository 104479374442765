<script>
  export let accentColor;
  import { validateRequired, validateEmail } from "../utils/validators.ts";
  import {
    client_id,
    messages,
    user_details_form_submitted,
  } from "../stores/chat.js";
  import LoaderIcon from "../utils/assets/loader_icon.svelte";
  export let clientLogoUrl = "";
  export let formFields = [];
  export let config = {};

  let formData = {};
  let errors = {};
  let isLoading = false;

  $: {
    formFields.forEach((field) => {
      if (formData[field.code] === undefined) {
        formData[field.code] = "";
      }
      if (errors[field.code] === undefined) {
        errors[field.code] = "";
      }
    });
  }

  $: canSubmit = formFields.every((field) => {
    if (field.mandatory) {
      if (field.type === "email") {
        return !validateEmail(formData[field.code]);
      } else {
        return !validateRequired(formData[field.code], field.name);
      }
    }
    return true;
  });

  const parseMessage = (message) => {
    try {
      const data = JSON.parse(
        localStorage.getItem("user_form_details") || "{}"
      );
      console.log(formFields, data);
      if (formFields?.length > 0 && Object?.keys(data)?.length > 0) {
        formFields?.forEach((field) => {
          if (data[field.code]) {
            const value = data[field.code]
              ?.split(" ")
              ?.map((word) => {
                return word[0].toUpperCase() + word.slice(1);
              })
              .join(" ");

            console.log("parse", message, value, data);

            message = message.replace(`{{${field.code}}}`, value);
          }
        });

        return message;
      }
    } catch (error) {
      return message;
    }
    return message;
  };

  function validateForm() {
    let valid = true;
    formFields.forEach((field) => {
      if (field.mandatory) {
        if (field.type === "email") {
          errors[field.code] = validateEmail(formData[field.code]);
        } else {
          errors[field.code] = validateRequired(
            formData[field.code],
            field.name
          );
        }
        if (errors[field.code]) valid = false;
      } else {
        if (formData[field.code]?.length > 0 && field.type === "email") {
          errors[field.code] = validateEmail(formData[field.code]);
          if (errors[field.code]) valid = false;
        } else {
          errors[field.code] = "";
        }
      }
    });
    return valid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      isLoading = true;
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/fe/upload_form_data/${client_id.get()}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...formData,
            user_id: localStorage.getItem("user_id"),
          }),
        }
      );
      if (response.ok) {
        localStorage.setItem("user_details_form_submitted", true);
        localStorage.setItem("user_form_details", JSON.stringify(formData));
        user_details_form_submitted.set(true);
        isLoading = false;
      } else {
        console.error("Failed to submit form");
        isLoading = false;
        user_details_form_submitted.set(false);
      }

      const newMessage = [...messages.get()];

      newMessage.forEach((message) => {
        if (message.role === "assistant")
          message.message = parseMessage(message.message);
      });
      messages.set(newMessage);
    }
  };

  function clearError(fieldCode) {
    errors[fieldCode] = "";
  }
</script>

<div class="verbaflo-user-details-form-container">
  <img src={clientLogoUrl} alt="Bot Profile" class="verbaflo-client-logo" />
  <div>
    <form
      class="verbaflo-user-details-form-form-container"
      on:submit={handleSubmit}
    >
      <div class="verbaflo-user-details-form-form-box">
        <p class="verbaflo-user-details-form-form-text">
          Please fill in the form below before starting the chat
        </p>

        {#each formFields as field}
          <div>
            {#if field.type === "email"}
              <input
                class="verbaflo-user-details-form-input"
                type="email"
                placeholder={field.name}
                bind:value={formData[field.code]}
                on:focus={() => clearError(field.code)}
                on:input={() => clearError(field.code)}
              />
            {:else}
              <input
                class="verbaflo-user-details-form-input"
                type="text"
                placeholder={field.name}
                bind:value={formData[field.code]}
                on:focus={() => clearError(field.code)}
                on:input={() => clearError(field.code)}
              />
            {/if}
            {#if errors[field.code]}
              <p class="verbaflo-user-details-form-error-text">
                {errors[field.code]}
              </p>
            {/if}
          </div>
        {/each}
      </div>
      <button
        type="submit"
        class="verbaflo-user-details-form-submit-button"
        disabled={!canSubmit || isLoading}
        style="--accent-color: {accentColor};"
      >
        <span>Start Chatting</span>
        {#if isLoading}
          <LoaderIcon />
        {/if}
      </button>
    </form>
  </div>
</div>

<style>
  :global(.verbaflo-user-details-form-container) {
    display: flex;
    align-items: flex-start;
    position: relative;
    gap: 8px;
    z-index: 10;
  }

  :global(.verbaflo-user-details-form-bot-image) {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    align-self: flex-start;
    margin-bottom: 16px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  :global(.verbaflo-user-details-form-form-container) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  :global(.verbaflo-user-details-form-form-box) {
    background-color: #f6f6f6 !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    max-width: 280px;
  }

  :global(.verbaflo-user-details-form-form-text) {
    font-size: 1rem;
    padding: 8px 0;
  }

  :global(.verbaflo-user-details-form-input) {
    font-size: 12px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    outline: none;
    box-shadow: none;
  }

  :global(.verbaflo-user-details-form-input:focus) {
    border: none;
    outline: none;
    box-shadow: none;
  }

  :global(.verbaflo-user-details-form-error-text) {
    color: #d80000 !important;
    font-size: 0.75rem;
    margin: 4px 0;
  }
  :global(.verbaflo-user-details-form-submit-button) {
    cursor: pointer;
    border-radius: 8px;
    height: 32px;
    background-color: var(--accent-color) !important;
    color: white !important;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    width: 100%;
    border: none;
    outline: none;
  }

  :global(.verbaflo-user-details-form-submit-button:focus) {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  :global(.verbaflo-user-details-form-submit-button:disabled) {
    cursor: not-allowed;
    opacity: 0.6;
  }
</style>
