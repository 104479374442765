import { atom } from "nanostores";

declare global {
  interface Window {
    dataLayer: any[];
  }
}
import axios from "axios";
import { clientUrlPatterns, type ChatMessage } from "../types/globalTypes";
import { getSessionId } from "./sessionManager";
import { AssetLoader } from "../utils/assetLoader";
import { isOpen, openWidget } from "./app";

export const socketManager = atom<WebSocket | null>(null);
export const userID = () => localStorage.getItem("user_id") || "";
export const botPresetMessageString = atom<string>(
  "Hello, how can I help you today?"
);

export const messages = atom<any[]>([]);
export const pendingMessages = atom<ChatMessage[]>([]);
export const chatMessages = atom<
  { message: string; role: string; timestamp: string }[]
>([]);
export const showInput = atom<boolean>(true);
export const client_id = atom<string | null>(null);
export const showThinking = atom<boolean>(false);
export const showSocketLoader = atom<boolean>(true);
export const activeAgentIndex = atom<number[]>([]);
export const activeAssistantIndex = atom<number[]>([]);
export const activeTyping = atom<boolean>(true);
export const property_name = atom<string | null>(null);
export const city_name = atom<string | null>(null);
export const user_details_form_submitted = atom<boolean>(false);
export const input_field_enabled = atom<boolean>(false);
export const selected_communication = atom<string>("message");
const historyLoaded = atom<boolean>(false);

const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3000";
export const setBotPresetMessageString = (value: string) => {
  botPresetMessageString.set(value);
};

export const setBotPresetMessage = () => {
  const currentMessages = messages.get();
  const hasUserOrServerMessages = currentMessages.some(
    (msg) =>
      msg.role === "user" || msg.role === "assistant" || msg.role === "agent"
  );

  if (!hasUserOrServerMessages) {
    const newWelcome: any = {
      message:
        botPresetMessageString.get() || "Hello, how can I help you today?",
      role: "assistant",
      timestamp: new Date().toISOString(),
      property_json: null,
    };
    let propertyName = property_name.get();
    let cityName = city_name.get();
    currentMessages.push(newWelcome);
    if (propertyName && cityName) {
      currentMessages.push({
        message: `I can see you're looking at **${propertyName}** in **${cityName}**. Are you just browsing or do you need any help?`,
        role: "assistant",
        timestamp: new Date(Date.now() + 5000).toISOString(),
        property_json: null,
      });
    }

    messages.set(currentMessages);
  }
};

const playMessageSound = () => {
  AssetLoader.getInstance().playNotification();
};

const getUserID = async () => {
  let currentUserID = localStorage.getItem("user_id");
  if (!currentUserID) {
    try {
      const response = await axios.get(`${API_URL}/fe/user_id`);
      const newID = response.data.user_id;
      localStorage.setItem("user_id", newID);
      currentUserID = newID;
    } catch (error) {
      console.error("Failed to get user ID");
    }
  }
  return currentUserID;
};

let isConnecting = false; // Prevents duplicate connections
let reconnectAttempts = 0; // Tracks reconnection attempts
const MAX_RECONNECT_ATTEMPTS = 5; // Limits retry attempts
const BASE_RECONNECT_DELAY = 1000; // Base delay for exponential backoff

export const connectSocket = async () => {
  if (socketManager.get() || isConnecting) return; // Prevent duplicate connections

  isConnecting = true; // Set connecting flag
  const id = await getUserID();
  if (!id) {
    isConnecting = false;
    return;
  }

  const session_id = getSessionId();
  let requestData = messages.get().length === 1; // if we only have the default message

  const wsUrl = API_URL.replace(/^http/, "ws");
  const socket = new WebSocket(
    `${wsUrl}/?user_id=${id}&tenant_id=${
      client_id.get() || ""
    }&session_id=${session_id}`
  );

  let keepAliveInterval: NodeJS.Timer | null = null;

  const startKeepAlive = () => {
    if (keepAliveInterval) clearInterval(keepAliveInterval);
    keepAliveInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: "keepalive" }));
      }
    }, 30000);
  };

  const attemptReconnect = () => {
    if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
      console.error("Max reconnection attempts reached");
      return;
    }

    const delay = BASE_RECONNECT_DELAY * Math.pow(2, reconnectAttempts); // Exponential backoff
    reconnectAttempts++;

    console.log(`Attempting to reconnect in ${delay / 1000} seconds...`);

    setTimeout(async () => {
      if (!socketManager.get() && !isConnecting) {
        await connectSocket();
      }
    }, delay);
  };

  socket.addEventListener("open", () => {
    console.log("Connected to WebSocket server");
    showSocketLoader.set(false);
    socketManager.set(socket);
    isConnecting = false; // Reset flag
    reconnectAttempts = 0; // Reset reconnection attempts
    startKeepAlive();
  });

  socket.addEventListener("message", (event) => {
    try {
      const receivedMessage_json =
        typeof event.data === "string" ? JSON.parse(event.data) : event.data;

      if (!receivedMessage_json) return;

      let receivedMessages: ChatMessage[] | null = null;

      if (Array.isArray(receivedMessage_json)) {
        messages.set([]);
        receivedMessages = receivedMessage_json.map((msg: any) => ({
          message: msg.message,
          role: msg.role,
          property_json: msg.property_json?.length ? msg.property_json : null,
          timestamp: msg.timestamp,
          agentName: msg.agent_name || "",
          quick_replies: msg.quick_replies || [],
          kind: msg?.kind || "",
        }));
        historyLoaded.set(true);
      } else if (receivedMessage_json.message) {
        receivedMessages = [
          {
            message: receivedMessage_json.message,
            role: receivedMessage_json.role,
            property_json: receivedMessage_json.property_json?.length
              ? receivedMessage_json.property_json
              : null,
            timestamp: receivedMessage_json.timestamp,
            agentName: receivedMessage_json.agent_name || "",
            quick_replies: receivedMessage_json.quick_replies || [],
            kind: receivedMessage_json?.kind || "",
          },
        ];
      } else if (
        receivedMessage_json.event_type === "toggle_communication_channel"
      ) {
        activeTyping.set(receivedMessage_json.event_details?.toggle_status);
      }

      if (receivedMessages) {
        const currentMessages = [...messages.get(), ...receivedMessages];
        messages.set(currentMessages);
        showThinking.set(false);
        activeAgentIndex.set(shouldDisplayAgentActive(currentMessages));
        activeAssistantIndex.set(shouldDisplayAssistantActive(currentMessages));

        if (!isOpen.get()) {
          pendingMessages.set([...pendingMessages.get(), ...receivedMessages]);
        }

        if (toggleShowInput(messages.get())) {
          showInput.set(true);
        }

        if (
          receivedMessage_json.message &&
          checkUserAssistantInteraction(messages.get())
        ) {
          updateUtmDetails(window.location.href);
        }

        if (
          client_id.get() === "vita" &&
          checkIfUserHasMessaged(messages.get())
        ) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "live_chat_started",
            chatProvider: "verbal",
            chatAction: "Message Sent",
          });
        }

        playMessageSound(); // Only play sound when there's a valid message
      }
    } catch (error) {
      console.error("Error processing message:", error);
    }
  });

  isOpen.subscribe((open) => {
    if (open) {
      pendingMessages.set([]);
    }
  });

  const updateUtmDetails = async (url: string) => {
    let currentUserID = localStorage.getItem("user_id");
    const utmValues = extractUtmDetails(url);
    if (currentUserID) {
      try {
        const response = await axios.post(
          `${API_URL}/fe/tracking/${client_id.get()}`,
          { user_id: currentUserID, tracking_info: { ...utmValues } }
        );
      } catch (error) {
        console.error("Failed to update utm sources");
      }
    }
    return currentUserID;
  };

  socket.addEventListener("close", () => {
    console.warn("WebSocket closed");
    socketManager.set(null);
    showThinking.set(false);
    activeTyping.set(true);
    showSocketLoader.set(true);
    isConnecting = false; //  Reset flag
    attemptReconnect(); //  Use backoff strategy for reconnecting
  });

  socket.addEventListener("error", (err) => {
    console.error("WebSocket Error:", err);
    socket.close();
    handleConnectionFailure();
  });

  const handleConnectionFailure = () => {
    console.error("Failed to establish WebSocket connection");
    showSocketLoader.set(true);
    attemptReconnect();
  };

  setTimeout(() => {
    if (socket.readyState !== WebSocket.OPEN) {
      console.error("WebSocket connection timeout");
      socket.close();
      handleConnectionFailure();
    }
  }, 5000);
};

export const resetConnection = async () => {
  // First, close the existing socket connection if it exists
  const currentSocket = socketManager.get();
  if (currentSocket) {
    currentSocket.close();
    socketManager.set(null);
  }

  // Clear local storage
  localStorage.removeItem("session_info");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_details_form_submitted");
  localStorage.removeItem("updateDataLayer");
  localStorage.removeItem("user_form_details");

  showInput.set(input_field_enabled.get());

  // Reset all states
  activeTyping.set(true);
  showThinking.set(false);
  messages.set([]);
  chatMessages.set([]);
  activeAgentIndex.set([]);
  activeAssistantIndex.set([]);
  if (client_id.get() === "unilife") {
    user_details_form_submitted.set(false);
  } else {
    user_details_form_submitted.set(true);
  }

  // Get new session and connect
  getSessionId();
  await getUserID();
  connectSocket();
};

export const displayMessage = (message: string, kind: string = "chat") => {
  let userNewMessage = {
    message: message,
    role: "user",
    timestamp: new Date().toISOString(),
    kind: kind,
  };
  let currentMessages = [...messages.get()];
  let messagesToSet = [];
  let propertyName = property_name.get();

  if (currentMessages.length <= 2 && currentMessages[0]?.role === "assistant") {
    messagesToSet.push({
      message: currentMessages[0]?.message,
      role: "assistant",
      timestamp: new Date().toISOString(),
    });
  }

  if (
    currentMessages.length === 2 &&
    propertyName &&
    currentMessages[1]?.role === "assistant"
  ) {
    messagesToSet.push({
      message: currentMessages[1]?.message,
      role: "assistant",
      timestamp: new Date().toISOString(),
    });
  }

  messagesToSet.push(userNewMessage);
  chatMessages.set([...chatMessages.get(), ...messagesToSet]);
  currentMessages.push(userNewMessage);
  messages.set(currentMessages);
  activeAgentIndex.set(shouldDisplayAgentActive(currentMessages));
  activeAssistantIndex.set(shouldDisplayAssistantActive(currentMessages));
};

export const sendMessage = () => {
  const socket = socketManager.get();
  if (socket && socket.readyState === WebSocket.OPEN) {
    let currentMessages = [...chatMessages.get()];
    socket.send(JSON.stringify({ messages: currentMessages }));
    chatMessages.set([]);
  } else {
    console.warn("WebSocket not connected. Message not sent.");
  }
};

export const shouldDisplayAgentActive = (messages: ChatMessage[]) => {
  let agentIndexes: number[] = [];
  const filteredMessages = messages
    .map((message, index) => ({ message, originalIndex: index }))
    .filter(
      (message: { message: ChatMessage }) => message.message.role !== "user"
    );

  if (filteredMessages.length === 0) return agentIndexes;
  filteredMessages.forEach((filteredMessage, filteredIndex) => {
    if (
      filteredMessage.message.role === "agent" &&
      filteredMessages[filteredIndex - 1]?.message.role === "assistant"
    ) {
      agentIndexes.push(filteredMessage.originalIndex);
    }
  });
  return agentIndexes;
};

export const shouldDisplayAssistantActive = (messages: ChatMessage[]) => {
  let assistantIndex: number[] = [];
  const filteredMessages = messages
    .map((message, index) => ({ message, originalIndex: index }))
    .filter(
      (message: { message: ChatMessage }) => message.message.role !== "user"
    );

  if (filteredMessages.length === 0) return assistantIndex;
  filteredMessages.forEach((filteredMessage, filteredIndex) => {
    if (
      filteredMessage.message.role === "assistant" &&
      filteredMessages[filteredIndex - 1]?.message.role === "agent"
    ) {
      assistantIndex.push(filteredMessage.originalIndex);
    }
  });
  return assistantIndex;
};

export const extractCityAndPropertyName = (url: string) => {
  const parsedUrl = new URL(url);
  const baseUrl = parsedUrl.origin;
  const hostname = parsedUrl.hostname;
  const segments = parsedUrl.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const clientConfig = clientUrlPatterns[hostname];

  if (!clientConfig) {
    return null;
  }

  if (segments.length >= clientConfig.minSegments) {
    const cityName = formatSegment(segments[clientConfig.cityIndex]);
    const propertyName = formatSegment(segments[clientConfig.propertyIndex]);

    property_name.set(propertyName);
    city_name.set(cityName);
  }
};

const formatSegment = (segment: string): string => {
  return segment
    .replaceAll("-", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const extractUtmDetails = (url: string) => {
  const parsedUrl = new URL(url);
  const params = new URLSearchParams(parsedUrl.search);

  return {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
    url: !(
      window.location.href.includes("localhost") ||
      window.location.href.includes("127.0.0.1")
    )
      ? window.location.href
      : "",
  };
};

function checkIfUserHasMessaged(messages: ChatMessage[]) {
  if (localStorage.getItem("updateDataLayer") === "true") {
    return false;
  }

  for (let i = 0; i < messages.length; i++) {
    const { role } = messages[i];

    if (role === "user") {
      localStorage.setItem("updateDataLayer", "true");
      return true;
    }
  }

  return false;
}
function checkUserAssistantInteraction(messages: ChatMessage[]) {
  let userMessageFound = false;
  let assistantOrAgentCount = 0;

  for (let i = 0; i < messages.length; i++) {
    const { role } = messages[i];

    if (role === "user") {
      userMessageFound = true;
    } else if (userMessageFound && (role === "assistant" || role === "agent")) {
      assistantOrAgentCount++;
    }
  }
  if (userMessageFound && assistantOrAgentCount === 1) {
    return true;
  }
  return false;
}

export function toggleShowInput(messages: ChatMessage[]) {
  let userMessageFound = false;
  let assistantOrAgentCount = 0;

  for (let i = 0; i < messages.length; i++) {
    const { role } = messages[i];

    if (role === "user") {
      userMessageFound = true;
    } else if (userMessageFound && (role === "assistant" || role === "agent")) {
      assistantOrAgentCount++;
    }
  }
  if (userMessageFound && assistantOrAgentCount >= 1) {
    return true;
  }
  return false;
}
