<script>
  import { onMount } from "svelte";

  let container;
  let slideCount = 0;
  let currentIndex = 0;
  let startX = 0;
  let currentX = 0;
  let isDragging = false;

  let containerWidth = 0;
  let slideWidth = 0;
  let gap = 12;
  const maxVisibleDots = 5;
  let visibleDots = [];

  $: offset =
    currentIndex === 0
      ? 0
      : currentIndex * (slideWidth + gap) - (containerWidth - slideWidth) / 2;

  $: {
    let start = 0;
    if (slideCount > maxVisibleDots) {
      start = Math.max(
        0,
        Math.min(
          currentIndex - Math.floor(maxVisibleDots / 2),
          slideCount - maxVisibleDots
        )
      );
    }
    visibleDots = Array.from(
      { length: Math.min(slideCount, maxVisibleDots) },
      (_, i) => start + i
    );
  }

  onMount(() => {
    slideCount = container.children.length;
    containerWidth = container.clientWidth;
    if (slideCount > 0) {
      slideWidth = container.children[0].offsetWidth;
    }
  });

  function goToSlide(index) {
    currentIndex = index;
  }

  function handleTouchStart(event) {
    isDragging = true;
    startX = event.touches[0].clientX;
  }

  function handleTouchMove(event) {
    if (!isDragging) return;
    currentX = event.touches[0].clientX;
    const deltaX = currentX - startX;
    container.style.transform = `translateX(${-offset + deltaX}px)`;
  }

  function handleTouchEnd() {
    if (!isDragging) return;
    isDragging = false;
    const deltaX = currentX - startX;
    const threshold = 50;
    if (deltaX > threshold && currentIndex > 0) {
      currentIndex = currentIndex - 1;
    } else if (deltaX < -threshold && currentIndex < slideCount - 1) {
      currentIndex = currentIndex + 1;
    }
    container.style.transform = `translateX(-${offset}px)`;
  }
</script>

<div class="verbaflo-carousel-container">
  <div
    bind:this={container}
    on:touchstart={handleTouchStart}
    on:touchmove={handleTouchMove}
    on:touchend={handleTouchEnd}
    class="verbaflo-carousel-slides"
    style="transform: translateX(-{offset}px);"
  >
    <slot></slot>
  </div>

  <div class="verbaflo-carousel-dots-container">
    <div class="verbaflo-carousel-dots-wrapper">
      {#each visibleDots as dot, i}
        <button
          on:click={() => goToSlide(dot)}
          class="verbaflo-carousel-dot-btn"
        >
          <span
            class="verbaflo-carousel-dot {dot === currentIndex
              ? 'verbaflo-carousel-dot-active'
              : i === 0 || i === visibleDots.length - 1
                ? 'verbaflo-carousel-dot-edge'
                : 'verbaflo-carousel-dot-middle'}"
          ></span>
        </button>
      {/each}
    </div>
    <div class="verbaflo-carousel-counter">
      {currentIndex + 1} of {slideCount}
    </div>
  </div>
</div>

<style>
  :global(.verbaflo-carousel-container) {
    position: relative;
    width: 100%;
  }

  :global(.verbaflo-carousel-slides) {
    display: flex;
    gap: 12px;
    transition: transform 500ms;
  }

  :global(.verbaflo-carousel-dots-container) {
    position: relative;
    margin-top: 1rem;
  }

  :global(.verbaflo-carousel-dots-wrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.verbaflo-carousel-dot-btn) {
    margin: 0 4px;
    border-radius: 9999px;
    outline: none;
    border: none;
    background: none;
    padding: 0;
  }

  :global(.verbaflo-carousel-dot) {
    display: inline-block;
    border-radius: 9999px;
  }

  :global(.verbaflo-carousel-dot-active) {
    width: 5px;
    height: 5px;
    background-color: black !important;
  }

  :global(.verbaflo-carousel-dot-edge) {
    width: 3px;
    height: 3px;
    background-color: #939393 !important;
  }

  :global(.verbaflo-carousel-dot-middle) {
    width: 4px;
    height: 4px;
    background-color: #939393 !important;
  }

  :global(.verbaflo-carousel-counter) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.75rem;
    color: #4b5563 !important;
  }
</style>
