export class AssetLoader {
  private static instance: AssetLoader;
  private assets: Map<string, any> = new Map();
  private cdnUrl: string;

  private constructor() {
    this.cdnUrl = this.getCDNUrl();
    this.preloadAssets();
  }

  static getInstance(): AssetLoader {
    if (!AssetLoader.instance) {
      AssetLoader.instance = new AssetLoader();
    }
    return AssetLoader.instance;
  }

  getCDNUrl(): string {
    // Try to get from window config first
    if ((window as any).__VERBAFLO_CONFIG__?.CDN_URL) {
      return (window as any).__VERBAFLO_CONFIG__.CDN_URL;
    }

    // Fallback to getting from script tag
    const scriptTag =
      document.currentScript || document.querySelector('script[src*="widget"]');
    if (scriptTag) {
      const src = (scriptTag as HTMLScriptElement).src;
      const url = new URL(src);
      return `${url.protocol}//${url.host}`;
    }

    return "http://localhost:8080";
  }

  private async preloadAssets() {
    try {
      // Use absolute URL for audio
      const audioUrl = new URL("/assets/sounds/notification.mp3", this.cdnUrl)
        .href;
      const notificationSound = new Audio(audioUrl);
      notificationSound.load();
      this.assets.set("notification", notificationSound);

      // Use absolute URL for images
      const poweredByUrl = new URL("/assets/images/powered_by.svg", this.cdnUrl)
        .href;
      const poweredBy = new Image();
      poweredBy.src = poweredByUrl;
      this.assets.set("poweredBy", poweredBy);
    } catch (error) {
      console.error("Failed to load assets:", error);
    }
  }

  getAsset(key: string) {
    return this.assets.get(key);
  }

  setAsset(key: string, value: any) {
    this.assets.set(key, value);
  }

  playNotification() {
    const audio = this.assets.get("notification") as HTMLAudioElement;
    if (audio) {
      audio.currentTime = 0;
      // Attempt to play sound only if autoplay is allowed
      audio.play().catch((err) => {
        if (err.name === "NotAllowedError") {
          //console.info("Autoplay not allowed, skipping sound playback.");
        } else {
          console.error("Error playing sound:", err);
        }
      });
    }
  }
}
