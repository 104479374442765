<svg
  width="30"
  height="30"
  viewBox="0 0 50 50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4895_74948)">
    <path
      d="M0.20839 24.7747C0.207176 29.1408 1.35312 33.4039 3.53211 37.1615L0 50L13.1978 46.555C16.8481 48.5333 20.938 49.5699 25.0942 49.5702H25.1051C38.8254 49.5702 49.9941 38.4556 50 24.7943C50.0026 18.1744 47.4154 11.9495 42.7147 7.26621C38.0148 2.58333 31.7641 0.00302121 25.1041 0C11.3821 0 0.214257 11.114 0.208592 24.7747"
      fill="url(#paint0_linear_4895_74948)"
    />
    <path
      d="M0.208984 24.7749C0.207617 29.1415 1.35352 33.4042 3.53203 37.1616L0 50L13.1975 46.5551C16.8338 48.5289 20.9279 49.5695 25.0939 49.5711H25.1047C38.825 49.5711 49.9941 38.4552 50 24.7948C50.0023 18.1744 47.4148 11.949 42.7148 7.26588C38.0143 2.58335 31.7643 0.00272218 25.1047 0C11.382 0 0.214453 11.1143 0.208984 24.7749ZM8.06855 36.5145L7.57578 35.7358C5.5043 32.4567 4.41094 28.6675 4.4125 24.7765C4.4168 13.4221 13.6988 4.18438 25.1125 4.18438C30.6398 4.18671 35.8344 6.33179 39.7414 10.2237C43.6482 14.1161 45.798 19.2901 45.7967 24.7932C45.7916 36.1476 36.5094 45.3865 25.1047 45.3865H25.0965C21.383 45.3845 17.741 44.3917 14.5648 42.5156L13.809 42.0693L5.97734 44.1135L8.06855 36.5145Z"
      fill="url(#paint1_linear_4895_74948)"
    />
    <path
      d="M18.8559 13.6614C18.3951 12.5571 17.9101 12.5348 17.4719 12.5154C17.1131 12.4988 16.7029 12.5 16.2931 12.5C15.8829 12.5 15.2164 12.6664 14.653 13.3297C14.0891 13.9935 12.5 15.5978 12.5 18.8607C12.5 22.1236 14.7042 25.2772 15.0115 25.7202C15.3191 26.1623 19.2667 33.0725 25.5187 35.7309C30.7146 37.9401 31.772 37.5007 32.8997 37.3899C34.0276 37.2796 36.5391 35.7861 37.0514 34.2374C37.5642 32.6889 37.5642 31.3616 37.4105 31.0842C37.2567 30.8079 36.8465 30.6419 36.2314 30.3104C35.6163 29.9789 32.5921 28.3742 32.0283 28.1528C31.4644 27.9317 31.0544 27.8213 30.6442 28.4854C30.2339 29.1484 29.0561 30.6419 28.697 31.0842C28.3384 31.5276 27.9794 31.5827 27.3644 31.251C26.7489 30.9183 24.768 30.2188 22.4178 27.9596C20.5892 26.2016 19.3548 24.0307 18.9959 23.3666C18.6371 22.7036 18.9575 22.3442 19.2659 22.0137C19.5423 21.7165 19.8812 21.2392 20.1891 20.8521C20.4958 20.4648 20.5981 20.1885 20.8032 19.7462C21.0085 19.3034 20.9058 18.9161 20.7522 18.5844C20.5981 18.2527 19.4028 14.9727 18.8559 13.6614Z"
      fill="white"
    />
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_4895_74948"
      x1="2500"
      y1="5000"
      x2="2500"
      y2="0"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#1FAF38" />
      <stop offset="1" stop-color="#60D669" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_4895_74948"
      x1="2500"
      y1="5000"
      x2="2500"
      y2="0"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#F9F9F9" />
      <stop offset="1" stop-color="white" />
    </linearGradient>
    <clipPath id="clip0_4895_74948">
      <rect width="50" height="50" fill="white" />
    </clipPath>
  </defs>
</svg>
