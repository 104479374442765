/**
 * Validates that a value is not empty.
 * @param {string} value
 * @returns {string} Error message if invalid; empty string otherwise.
 */
export function validateRequired(value: string,name:string): string {
    return value?.trim() === "" ? `${name} is required` : "";
  }
  
  /**
   * Validates an email address.
   * @param {string} value
   * @returns {string} Error message if invalid; empty string otherwise.
   */
  export function validateEmail(value: string): string {
    if (value?.trim() === "") return "Email is required";
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern?.test(value) ? "" : "Invalid email address";
  }
  