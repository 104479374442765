<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1.3387 14L15.332 8L1.3387 2L1.33203 6.66667L11.332 8L1.33203 9.33333L1.3387 14Z"
    fill="white"
  />
</svg>
