<script>
  import { onMount } from "svelte";
  import ChevronLeft from "../utils/assets/chevron_left.svelte";
  import ChevronRight from "../utils/assets/chevron_right.svelte";

  let container;
  let slideCount = 0;
  let currentIndex = 0;
  let startX = 0;
  let currentX = 0;
  let isDragging = false;
  let startY = 0;
  let currentY = 0;
  let isHorizontalSwipe = false;
  let swipeDetectionThreshold = 5;

  let containerWidth = 0;
  let slideWidth = 0;
  let gap = 12;
  const maxVisibleDots = 5;
  let visibleDots = [];

  $: offset =
    currentIndex === 0
      ? 0
      : currentIndex * (slideWidth + gap) - (containerWidth - slideWidth) / 2;

  $: {
    let start = 0;
    if (slideCount > maxVisibleDots) {
      start = Math.max(
        0,
        Math.min(
          currentIndex - Math.floor(maxVisibleDots / 2),
          slideCount - maxVisibleDots
        )
      );
    }
    visibleDots = Array.from(
      { length: Math.min(slideCount, maxVisibleDots) },
      (_, i) => start + i
    );
  }

  onMount(() => {
    slideCount = container.children.length;
    containerWidth = container.clientWidth;
    if (slideCount > 0) {
      slideWidth = container.children[0].offsetWidth;
    }
  });

  function goToSlide(index) {
    currentIndex = index;
  }

  function handleTouchStart(event) {
    isDragging = true;
    startX = event.touches[0].clientX;
    startY = event.touches[0].clientY;
    isHorizontalSwipe = false;
  }

  function handleTouchMove(event) {
    if (!isDragging) return;

    currentX = event.touches[0].clientX;
    currentY = event.touches[0].clientY;

    // Determine swipe direction if not already determined
    if (!isHorizontalSwipe) {
      const deltaX = Math.abs(currentX - startX);
      const deltaY = Math.abs(currentY - startY);

      if (
        deltaX > swipeDetectionThreshold ||
        deltaY > swipeDetectionThreshold
      ) {
        isHorizontalSwipe = deltaX > deltaY;
      }
    }

    // If horizontal swipe, prevent default behavior and handle the swipe
    if (isHorizontalSwipe) {
      event.preventDefault();
      const deltaX = currentX - startX;
      container.style.transform = `translateX(${-offset + deltaX}px)`;
    }
  }

  function handleTouchEnd() {
    if (!isDragging) return;
    isDragging = false;

    if (isHorizontalSwipe) {
      const deltaX = currentX - startX;
      const threshold = 50;

      if (deltaX > threshold) {
        if (currentIndex === 0) {
          currentIndex = slideCount - 1;
        } else {
          currentIndex = currentIndex - 1;
        }
      } else if (deltaX < -threshold) {
        if (currentIndex === slideCount - 1) {
          currentIndex = 0;
        } else {
          currentIndex = currentIndex + 1;
        }
      }

      container.style.transform = `translateX(-${offset}px)`;
    }

    isHorizontalSwipe = false;
  }
</script>

<div class="verbaflo-carousel-container">
  <div
    bind:this={container}
    on:touchstart={handleTouchStart}
    on:touchmove={handleTouchMove}
    on:touchend={handleTouchEnd}
    class="verbaflo-carousel-slides"
    style:--offset={`${offset}px`}
  >
    <slot></slot>
  </div>

  <div class="verbaflo-carousel-dots-container">
    <div class="verbaflo-carousel-dots-wrapper">
      {#each visibleDots as dot, i}
        <button
          on:click={() => goToSlide(dot)}
          class="verbaflo-carousel-dot-btn"
        >
          <span
            class="verbaflo-carousel-dot {dot === currentIndex
              ? 'verbaflo-carousel-dot-active'
              : i === 0 || i === visibleDots.length - 1
                ? 'verbaflo-carousel-dot-edge'
                : 'verbaflo-carousel-dot-middle'}"
          ></span>
        </button>
      {/each}
    </div>
    <div class="verbaflo-carousel-counter">
      <div>
        <p>
          {currentIndex + 1} of {slideCount}
        </p>
      </div>
      <div class="verbaflo-nav-btn-container">
        <button
          class="verbaflo-nav-btn"
          on:click={() => {
            currentIndex =
              currentIndex === 0 ? slideCount - 1 : currentIndex - 1;
          }}
        >
          <ChevronLeft size={12} color="black" />
        </button>
        <button
          class="verbaflo-nav-btn"
          on:click={() => {
            currentIndex =
              currentIndex === slideCount - 1 ? 0 : currentIndex + 1;
          }}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  :global(.verbaflo-nav-btn-container) {
    display: flex !important;
    align-items: center !important;
    margin-left: 10px !important;
    column-gap: 12px !important;
  }

  :global(.verbaflo-nav-btn) {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  :global(.verbaflo-carousel-left-nav-btn) {
    rotate: 180deg !important;
    background: none !important;
    border: none !important;
    padding: 0 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  :global(.verbaflo-carousel-right-nav-btn) {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  :global(.verbaflo-carousel-container) {
    position: relative !important;
    width: 100% !important;
  }

  :global(.verbaflo-carousel-slides) {
    display: flex !important;
    gap: 12px !important;
    transition: transform 500ms !important;
    position: relative !important;
    isolation: isolate !important;
    -webkit-transform: translateZ(0) translateX(calc(-1 * var(--offset, 0))) !important;
    transform: translateZ(0) translateX(calc(-1 * var(--offset, 0))) !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    height: fit-content !important;
  }

  :global(.verbaflo-carousel-dots-container) {
    position: relative !important;
    margin-top: 1rem !important;
  }

  :global(.verbaflo-carousel-dots-wrapper) {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  :global(.verbaflo-carousel-dot-btn) {
    margin: 0 4px !important;
    border-radius: 9999px !important;
    outline: none !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
  }

  :global(.verbaflo-carousel-dot) {
    display: inline-block !important;
    border-radius: 9999px !important;
  }

  :global(.verbaflo-carousel-dot-active) {
    width: 5px !important;
    height: 5px !important;
    background-color: black !important;
  }

  :global(.verbaflo-carousel-dot-edge) {
    width: 3px !important;
    height: 3px !important;
    background-color: #939393 !important;
  }

  :global(.verbaflo-carousel-dot-middle) {
    width: 4px !important;
    height: 4px !important;
    background-color: #939393 !important;
  }

  :global(.verbaflo-carousel-counter) {
    position: absolute !important;
    right: 0 !important;
    top: 50% !important;
    display: flex !important;
    align-items: center !important;
    transform: translateY(-50%) !important;
    font-size: 0.75rem !important;
    color: #4b5563 !important;
  }
</style>
