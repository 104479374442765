<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_4895_74691)">
    <path
      d="M10.0312 0.937521C8.41858 0.93529 6.83461 1.36402 5.44323 2.17935C4.05186 2.99469 2.90362 4.167 2.11733 5.57499C1.33104 6.98299 0.935259 8.57551 0.970947 10.1878C1.00663 11.8001 1.47249 13.3735 2.32031 14.7453L0.90625 19.0625L6.1625 18.186C7.39118 18.7696 8.73547 19.0691 10.0957 19.0624C11.4559 19.0557 12.7972 18.7428 14.02 18.147C15.2429 17.5513 16.3159 16.6879 17.1596 15.6209C18.0032 14.5539 18.5959 13.3107 18.8936 11.9834C19.1913 10.6561 19.1864 9.27888 18.8793 7.95376C18.5721 6.62865 17.9707 5.38967 17.1194 4.3287C16.2682 3.26773 15.189 2.41199 13.962 1.82494C12.7349 1.23789 11.3915 0.934585 10.0312 0.937521Z"
      fill="#26CC64"
    />
    <path
      d="M0.90602 19.375C0.856696 19.375 0.808082 19.3633 0.764152 19.3408C0.720223 19.3184 0.682226 19.2859 0.65327 19.246C0.624313 19.206 0.60522 19.1598 0.597551 19.1111C0.589881 19.0624 0.593855 19.0125 0.609145 18.9656L1.9779 14.7844C0.922039 13.0051 0.484412 10.9266 0.733211 8.87263C0.982011 6.81869 1.90326 4.90474 3.3534 3.42905C4.80353 1.95335 6.7011 0.998806 8.75038 0.714162C10.7997 0.429518 12.8855 0.830765 14.683 1.85538C16.4804 2.87999 17.8884 4.4704 18.6876 6.37878C19.4867 8.28716 19.6322 10.4063 19.1012 12.406C18.5702 14.4056 17.3927 16.1735 15.7521 17.434C14.1115 18.6946 12.1 19.377 10.031 19.375C8.6816 19.3744 7.34854 19.0796 6.12477 18.5109L0.96852 19.3703C0.94792 19.3742 0.926962 19.3757 0.90602 19.375ZM6.16227 17.8734C6.20883 17.8737 6.25474 17.8844 6.29665 17.9047C7.46291 18.4612 8.7388 18.75 10.031 18.75C11.9738 18.7548 13.8631 18.1146 15.4027 16.9297C16.9423 15.7448 18.045 14.0824 18.5378 12.2032C19.0306 10.3239 18.8856 8.33431 18.1255 6.54641C17.3655 4.7585 16.0334 3.2735 14.3383 2.32437C12.6431 1.37525 10.6809 1.01571 8.75939 1.30216C6.83788 1.58862 5.06582 2.50486 3.72124 3.90714C2.37666 5.30941 1.53565 7.11838 1.33013 9.05023C1.12462 10.9821 1.56624 12.9275 2.58571 14.5813C2.60955 14.6199 2.6247 14.6632 2.6301 14.7083C2.63549 14.7533 2.63101 14.799 2.61696 14.8422L1.36696 18.6688L6.11383 17.8781C6.12982 17.8752 6.14602 17.8736 6.16227 17.8734Z"
      fill="#26CC64"
    />
    <path
      d="M4.5469 6.96484C4.54482 9.25803 5.45326 11.4582 7.07259 13.082C8.69192 14.7057 10.8897 15.6201 13.1828 15.6242H13.4656C13.775 15.6069 14.077 15.5233 14.3513 15.3793C14.6256 15.2352 14.8658 15.034 15.0557 14.7892C15.2455 14.5444 15.3807 14.2617 15.4519 13.9601C15.5232 13.6586 15.5289 13.3453 15.4688 13.0414C15.4411 12.9006 15.3992 12.7629 15.3438 12.6305L11.7188 12.0523C11.4503 12.3646 11.2772 12.7476 11.2203 13.1555C10.2606 12.8465 9.38579 12.3187 8.66502 11.6136C7.94426 10.9086 7.39726 10.0457 7.06721 9.09297C7.46251 9.02879 7.83209 8.85564 8.1344 8.59297L7.56408 4.98047C7.40502 4.9066 7.2369 4.85407 7.06408 4.82422C6.94594 4.80347 6.82622 4.79302 6.70627 4.79297C6.42176 4.79338 6.14013 4.84992 5.87751 4.95937C5.61489 5.06882 5.37645 5.22901 5.17585 5.43077C4.97525 5.63253 4.81644 5.87189 4.70851 6.13513C4.60058 6.39838 4.54566 6.68034 4.5469 6.96484Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_4895_74691">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>
