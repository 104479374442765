<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.33464 3.33337H16.668C17.5846 3.33337 18.3346 4.08337 18.3346 5.00004V15C18.3346 15.9167 17.5846 16.6667 16.668 16.6667H3.33464C2.41797 16.6667 1.66797 15.9167 1.66797 15V5.00004C1.66797 4.08337 2.41797 3.33337 3.33464 3.33337Z"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18.3346 5L10.0013 10.8333L1.66797 5"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
