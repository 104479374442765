<script lang="ts">
  import "./app.css";
  import LogoIcon from "./utils/assets/logo.svelte";
  import CrossIcon from "./utils/assets/cross_icon.svelte";
  import AddIcon from "./utils/assets/add_icon.svelte";
  import ChatBubbleIcon from "./utils/assets/chat_bubble_icon.svelte";
  import { marked } from "marked";
  import {
    configLoaded,
    isOpen,
    openWidget,
    toggleWidget,
    isConfigLoaded,
    hasOpened,
  } from "./stores/app";
  import { fly } from "svelte/transition";
  import { AssetLoader } from "./utils/assetLoader";
  import {
    client_id,
    messages,
    sendMessage,
    showThinking,
    showSocketLoader,
    setBotPresetMessageString,
    activeAgentIndex,
    activeAssistantIndex,
    activeTyping,
    setBotPresetMessage,
    extractCityAndPropertyName,
    displayMessage,
    showInput,
    resetConnection,
    toggleShowInput,
    user_details_form_submitted,
    pendingMessages,
    input_field_enabled,
    selected_communication,
  } from "./stores/chat";
  import PropertyCarousel from "./components/PropertyCarousel.svelte";
  import { onMount, afterUpdate } from "svelte";
  import he from "he";
  import UserDetailsForm from "./components/UserDetailsForm.svelte";
  import EmailIcon from "./utils/assets/email_icon.svelte";
  import PhoneIcon from "./utils/assets/phone_icon.svelte";
  import WhatsappIcon from "./utils/assets/whatsapp_icon.svelte";
  import MessageIcon from "./utils/assets/message_icon.svelte";
  import WhatsappLogo from "./utils/assets/whatsapp_logo.svelte";
  import MenuIcon from "./utils/assets/menu_icon.svelte";
  import PhoneSolidIcon from "./utils/assets/phone_solid_icon.svelte";
  import WhatsappSolidIcon from "./utils/assets/whatsapp_solid_icon.svelte";
  import EmailSolidIcon from "./utils/assets/email_solid_icon.svelte";
  import MessageSolidIcon from "./utils/assets/message_solid_icon.svelte";
  import FooterLogo from "./utils/assets/footer_logo.svelte";
  import SendIcon from "./utils/assets/send_icon.svelte";
  import ChevronLeftIcon from "./utils/assets/chevron_left.svelte";
  import ThinkingIndicator from "./utils/assets/thinking_indicator.svelte";
  import User from "./utils/assets/user.svelte";

  export let headerName = "Verbaflo";
  export let botName = "VeeVee";
  export let clientName = "Verbaflo";
  export let clientLogoUrl = "https://vf-assets.verbaflo.com/fallback-logo.png";
  export let id;
  export let userProfileIcon = "https://www.gravatar.com/avatar?d=mp";
  export let accentColor = "#000000";
  export let clientWidgetIcon = "";
  const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3000";

  let userPresetMessages: any[] = [];

  if (!id) {
    throw new Error("Please provide a valid id");
  } else {
    $client_id = id;
  }

  let config: any;
  let newMessage: string;
  let debounceTimeout: ReturnType<typeof setTimeout>;
  let sendMessageFlag: boolean = false;
  let showOptions: boolean = false;
  let cdnUrl: string;
  let popupMessage: string;
  let currentDashboardUrl = "";
  let phoneLink: string = "";
  let whatsappLink: string = "";
  let emailLink: string = "";
  let windowWidth: number;
  let isKeyboardOpen = false;
  let modalityConfig = {
    chat_enabled: false,
    call_enabled: false,
    mail_enabled: false,
    whatsapp_enabled: false,
  };

  onMount(() => {
    windowWidth = window.innerWidth;
    const handleResize = () => {
      windowWidth = window.innerWidth;
    };

    // Handle input focus (keyboard opening)
    const handleFocus = () => {
      if (windowWidth < 700) {
        isKeyboardOpen = true;
        document.body.style.setProperty("height", "100vh", "important");
        document.body.style.setProperty("overflow", "hidden", "important");
        document.body.style.setProperty("position", "fixed", "important");
        document.body.style.setProperty("width", "100%", "important");
      }
    };

    // Handle input blur (keyboard closing)
    const handleBlur = () => {
      if (windowWidth < 700) {
        isKeyboardOpen = false;
        if (!$isOpen) {
          document.body.style.removeProperty("height");
          document.body.style.removeProperty("overflow");
          document.body.style.removeProperty("position");
          document.body.style.removeProperty("width");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    // Add event listeners for all input fields in the chat
    const inputs = document.querySelectorAll(".verbaflo-input");
    inputs.forEach((input) => {
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
      });
    };
  });

  // Modified reactive statement to handle both conditions
  $: if ($isOpen && windowWidth < 700) {
    document.body.style.setProperty("height", "100vh", "important");
    document.body.style.setProperty("overflow", "hidden", "important");
    document.body.style.setProperty("position", "fixed", "important");
    document.body.style.setProperty("width", "100%", "important");
  } else if (!isKeyboardOpen) {
    document.body.style.removeProperty("height");
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("width");
  }

  onMount(async () => {
    const assetLoader = AssetLoader.getInstance();
    cdnUrl = assetLoader.getCDNUrl();
    try {
      const response = await fetch(`${API_URL}/fe/get_client_config/${id}`);
      config = await response.json();
      if (!config) return;
      // Set config values before marking as loaded
      headerName = config.header_name;
      botName = config.bot_name;
      clientName = config.client_name;
      clientLogoUrl = config.client_logo_url;
      clientWidgetIcon = config.client_widget_icon;
      userProfileIcon = config.user_profile_icon;
      accentColor = config.accent_color ?? accentColor;
      userPresetMessages =
        config?.suggested_questions?.length > 0
          ? config?.suggested_questions
          : [];
      popupMessage = config?.popup_message;
      phoneLink = config?.call_link;
      whatsappLink = config?.whatsapp_link;
      emailLink = config?.email_link;
      modalityConfig = config?.modality_config;
      input_field_enabled.set(config?.initial_chat_box_input);

      if (config?.form_fields?.enabled) {
        user_details_form_submitted.set(
          localStorage.getItem("user_details_form_submitted") ? true : false
        );
      } else {
        user_details_form_submitted.set(true);
      }
      if (sessionStorage.getItem("chatbot_opened")) {
        hasOpened.set(sessionStorage.getItem("chatbot_opened") === "true");
      }

      //default show input value
      input_field_enabled.get()
        ? showInput.set(true)
        : showInput.set(toggleShowInput(messages.get()));

      if (config?.welcome_message) {
        setBotPresetMessageString(config?.welcome_message);
      }

      if (config?.selected_alert_sound) {
        const audioUrl = new URL(config.selected_alert_sound).href;
        const notificationSound = new Audio(audioUrl);
        notificationSound.load();
        assetLoader.setAsset("notification", notificationSound);
      }

      configLoaded();

      if (isConfigLoaded.get()) {
        const delayBot = (config?.open_delay ?? 0) * 1000;
        let phoneDelayBot = -1;
        if (config?.phone_delay >= 0) {
          phoneDelayBot = (config?.phone_delay ?? 0) * 1000;
        } else {
          phoneDelayBot = config?.phone_delay;
        }

        if (window.innerWidth < 700) {
          if (phoneDelayBot >= 0) {
            setTimeout(() => {
              openWidget();
            }, phoneDelayBot);
          }
        } else {
          setTimeout(() => {
            openWidget();
          }, delayBot);
        }

        if (!currentDashboardUrl) {
          currentDashboardUrl = window.location.href;
          handleUrlChange(currentDashboardUrl);
        }

        setBotPresetMessage();
      }
    } catch (error) {
      console.error("Failed to load config:", error);
    }
  });

  const handleUrlChange = (url: string) => {
    extractCityAndPropertyName(url);
  };

  const parseMessage = (message: string) => {
    try {
      const data = JSON.parse(
        localStorage.getItem("user_form_details") || "{}"
      );

      if (
        config?.form_fields?.fields?.length > 0 &&
        Object?.keys(data)?.length > 0
      ) {
        config?.form_fields?.fields?.forEach((field: { code: string }) => {
          if (data[field.code]) {
            const value = data[field.code]
              ?.split(" ")
              ?.map((word: string) => {
                return word[0].toUpperCase() + word.slice(1);
              })
              .join(" ");
            message = message?.replace(`{{${field.code}}}`, value);
          }
        });

        return message;
      }
    } catch (error) {
      return message;
    }
    return message;
  };

  const sendBatchedMessages = () => {
    showThinking.set(true);
    sendMessage();
    sendMessageFlag = false;
  };

  const handleKeyDown = () => {
    if (sendMessageFlag) {
      if (debounceTimeout) clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        sendBatchedMessages();
      }, 3000);
    }
  };

  const handleSubmit = (event: Event | null) => {
    if (event) {
      event.preventDefault();
    }
    if (newMessage.trim()) {
      sendMessageFlag = true;
      displayMessage(newMessage.trim());
      newMessage = "";
      if (debounceTimeout) clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        sendBatchedMessages();
      }, 2000);
    }
  };

  const handleCommunicationToggle = (type: string) => {
    selected_communication.set(type);
  };

  const startNewConversation = () => {
    resetConnection();
    userPresetMessages =
      config.suggested_questions?.length > 0 ? config.suggested_questions : [];

    if (config?.welcome_message) {
      setBotPresetMessageString(config.welcome_message);
    }
    setBotPresetMessage();
    showOptions = false;
  };

  let chatContainer: HTMLDivElement;
  let previousMessageCount = $messages.length + $pendingMessages.length;

  afterUpdate(() => {
    if (chatContainer) {
      const currentMessageCount = $messages.length + $pendingMessages.length;
      if (
        currentMessageCount > previousMessageCount ||
        sendMessageFlag ||
        $showThinking
      ) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
        previousMessageCount = currentMessageCount;
      }
    }
    if ($messages && $messages.length > 0) {
      const newMessage = [...$messages];
      newMessage?.forEach((message) => {
        if (message.role === "assistant") {
          message.message = parseMessage(message.message);
        }
      });
      messages.set(newMessage);
    }
  });

  function clickOutside(node: HTMLElement) {
    const handleClick = (event: MouseEvent) => {
      if (!node.contains(event.target as Node)) {
        showOptions = false;
      }
    };

    document.addEventListener("click", handleClick, true);

    return {
      destroy() {
        document.removeEventListener("click", handleClick, true);
      },
    };
  }
</script>

<div class="verbaflo-widget-container">
  <div class="verbaflo-widget-communication-btn-container">
    {#if $isConfigLoaded && $client_id === "londonist" && modalityConfig.call_enabled && phoneLink}
      <button
        on:click={(e) => {
          e.preventDefault();
          window.location.href = `tel:${phoneLink.replace(/[^\d+]/g, "")}`;
        }}
        type="button"
        class="verbaflo-widget-communication-btn"
      >
        <PhoneSolidIcon color={"#FFFFFF"} size={"30"} />
        <span class="verbaflo-widget-communication-text">Call Us!</span>
      </button>
    {/if}
    {#if $isConfigLoaded && $client_id === "londonist" && modalityConfig.whatsapp_enabled && whatsappLink}
      <button
        on:click={() => window.open(whatsappLink)}
        type="button"
        class="verbaflo-widget-communication-btn"
      >
        <WhatsappLogo />
        <span class="verbaflo-widget-communication-text">Let's chat</span>
      </button>
    {/if}
  </div>
  {#if $isConfigLoaded}
    <button
      aria-haspopup="dialog"
      aria-expanded="false"
      data-state="closed"
      on:click={toggleWidget}
      class="verbaflo-widget-btn"
    >
      {#if $isOpen}
        <CrossIcon />
      {:else}
        <LogoIcon />

        {#if $pendingMessages?.length > 0 || !$hasOpened}
          <div class="verbaflo-widget-btn-dot"></div>
        {/if}
        {#if !$hasOpened && popupMessage}
          <div class="verbaflo-widget-chat-bubble-container">
            <ChatBubbleIcon />
            <p class="verbaflo-widget-chat-bubble-popup-message">
              {popupMessage}
            </p>
          </div>
        {/if}
      {/if}
    </button>
  {/if}
  {#if $isOpen}
    <div
      in:fly={{ y: 50, duration: 300 }}
      out:fly={{ y: 50, duration: 100 }}
      class="verbaflo-chat-popup"
    >
      <div style="--accent-color: {accentColor};" class="verbaflo-chat-header">
        <div class="verbaflo-chat-header-content">
          <!-- Left section: Logo and Title -->
          <div class="verbaflo-chat-left">
            <button
              type="button"
              class="verbaflo-back-btn"
              on:click={toggleWidget}
            >
              <ChevronLeftIcon />
            </button>
            <img src={clientLogoUrl} alt="logo" class="verbaflo-client-logo" />
            <h2 class="verbaflo-chat-title">
              {headerName}
            </h2>
          </div>
          <div class="verbaflo-menu-container">
            <button
              type="button"
              on:click={() => (showOptions = !showOptions)}
              class="verbaflo-menu-icon"
            >
              <MenuIcon />
            </button>

            {#if showOptions}
              <button
                class="verbaflo-options-menu"
                on:click={startNewConversation}
                use:clickOutside
              >
                <AddIcon />
                <span class="verbaflo-new-chat-btn">New Chat</span>
              </button>
            {/if}
          </div>
        </div>
      </div>

      <!-- Chat Container -->
      <div
        bind:this={chatContainer}
        class="verbaflo-chat-container scrollbar-hide"
      >
        <div class="verbaflo-chat-messages-container">
          {#if $selected_communication === "phone" || $selected_communication === "whatsapp" || $selected_communication === "email"}
            <div class="verbaflo-message" class:verbaflo-message-left={true}>
              <div class="verbaflo-bot-message-parent-wrapper">
                <img
                  src={clientLogoUrl}
                  alt="Bot Profile"
                  class="verbaflo-bot-profile"
                />
                <div>
                  <div class="verbaflo-bot-message-parent">
                    <p class="verbaflo-bot-name">
                      {botName}
                    </p>
                    <div class="verbaflo-bot-message-container">
                      {#if $selected_communication === "phone"}
                        <p class="verbaflo-bot-message-container-text">
                          Prefer to speak over the phone? Tap below to call us.
                        </p>
                      {/if}
                      {#if $selected_communication === "whatsapp"}
                        <p class="verbaflo-bot-message-container-text">
                          Want to chat on WhatsApp? Tap below to start the
                          conversation.
                        </p>
                      {/if}
                      {#if $selected_communication === "email"}
                        <p class="verbaflo-bot-message-container-text">
                          Got questions? Send us an email and we'll reply soon!
                        </p>
                      {/if}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/if}
        </div>
        {#if config?.form_fields && config?.form_fields?.enabled && !$user_details_form_submitted && $selected_communication === "message"}
          <div class="verbaflo-message verbaflo-message-left">
            <UserDetailsForm
              {clientLogoUrl}
              formFields={config?.form_fields?.fields}
              {accentColor}
            />
          </div>
        {:else}
          <div class="verbaflo-chat-messages-container">
            {#if $selected_communication === "message"}
              {#each $messages as message, index}
                <div
                  class="verbaflo-message"
                  class:verbaflo-message-left={message.role === "assistant"}
                  class:verbaflo-message-right={message.role === "user"}
                >
                  {#if message?.role === "assistant" && message?.message !== undefined && !message?.property_json}
                    <div>
                      {#if $activeAssistantIndex.includes(index)}
                        <div class="verbaflo-divider-container">
                          <hr />
                          <p class="verbaflo-divider-text">
                            <span class="captialize">{botName}</span> joined the
                            chat
                          </p>
                          <hr />
                        </div>
                      {/if}

                      <div class="verbaflo-bot-message-parent-wrapper">
                        <img
                          src={clientLogoUrl}
                          alt="Bot Profile"
                          class="verbaflo-bot-profile"
                        />

                        <!-- Bot Message -->
                        <div>
                          <div class="verbaflo-bot-message-parent">
                            <p class="verbaflo-bot-name">
                              {botName}
                            </p>
                            <div class="verbaflo-bot-message-container">
                              {#await marked.parse(message.message.replace(/\n/g, "</br>")) then parsedMessage}
                                {@html he
                                  .decode(parsedMessage)
                                  .replace(
                                    /<a /g,
                                    '<a target="_blank" rel="noopener noreferrer" class="verbaflo-bot-message-link"'
                                  )
                                  .replace(
                                    /(\+\d{1,3}[\s\-.]?)?((?:[0-9][\s\-.]?){6,}[0-9])/g,
                                    '<a href="tel:$&" class="verbaflo-bot-message-link">$&</a>'
                                  )}
                              {/await}
                            </div>
                          </div>
                          {#if index === $messages.length - 1 && message.quick_replies && message.quick_replies.length > 0}
                            <div class="verbaflo-quick-replies">
                              {#each message.quick_replies as option}
                                <button
                                  style="--accent-color: {accentColor};"
                                  on:click={() => {
                                    displayMessage(option.text, "quickreply");
                                    sendBatchedMessages();
                                  }}
                                >
                                  {option.text}
                                </button>
                              {/each}
                            </div>
                          {/if}
                          <div class="verbaflo-timestamp">
                            {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  {/if}

                  {#if message?.property_json && message?.property_json?.length > 0}
                    <div class="verbaflo-bot-message-parent-wrapper">
                      <img
                        src={clientLogoUrl}
                        alt="Bot Profile"
                        class="verbaflo-bot-profile"
                      />
                      <div class="verbaflo-property-card-container">
                        <PropertyCarousel {cdnUrl} {accentColor} {message} />
                      </div>
                    </div>
                  {/if}

                  <!-- Agent Message -->
                  {#if message.role === "agent" && message.message !== undefined}
                    <div class="verbaflo-global-agent-message-wrapper">
                      {#if $activeAgentIndex.includes(index)}
                        <div class="verbaflo-global-agent-join-message">
                          <hr class="verbaflo-global-divider" />
                          <p class="verbaflo-global-join-text">
                            <span class="verbaflo-global-agent-name"
                              >{message.agentName}</span
                            > joined the chat
                          </p>
                          <hr class="verbaflo-global-divider" />
                        </div>
                      {/if}
                      <div class="verbaflo-global-message-container">
                        <img
                          src={clientLogoUrl}
                          alt="Bot Profile"
                          class="verbaflo-global-profile-image"
                        />
                        <!-- Message Content -->
                        <div class="verbaflo-global-message-content">
                          <div
                            class="verbaflo-global-bot-message-parent-container"
                          >
                            <p class="verbaflo-global-agent-name">
                              {message.agentName}
                            </p>
                            <div class="verbaflo-global-bot-message-container">
                              {#await marked.parse(message.message.replace(/\n/g, "</br>")) then parsedMessage}
                                {@html he
                                  .decode(parsedMessage)
                                  .replace(
                                    /<a /g,
                                    '<a target="_blank" rel="noopener noreferrer" class="verbaflo-global-bot-link"'
                                  )
                                  .replace(
                                    /(\+\d{1,3}[\s\-.]?)?((?:[0-9][\s\-.]?){6,}[0-9])/g,
                                    '<a href="tel:$&" class="verbaflo-global-bot-link">$&</a>'
                                  )}
                              {/await}
                            </div>
                          </div>
                          <div class="verbaflo-global-timestamp">
                            {new Date(message.timestamp).toLocaleTimeString(
                              [],
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  {/if}

                  <!-- User Message -->
                  {#if message.role === "user"}
                    <div class="verbaflo-global-message-container">
                      <div class="verbaflo-global-message-content">
                        <div
                          style="--accent-color: {accentColor};"
                          class="verbaflo-global-user-message-container"
                        >
                          {#await marked.parse(message?.message) then parsedMessage}
                            {@html he
                              .decode(parsedMessage)
                              .replace(
                                /<a /g,
                                '<a target="_blank" rel="noopener noreferrer" class="verbaflo-global-user-link"'
                              )}
                          {/await}
                        </div>
                        <div
                          class="verbaflo-global-timestamp verbaflo-global-user-timestamp"
                        >
                          {new Date(message.timestamp).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </div>
                      </div>
                      {#if userProfileIcon}
                        <img
                          src={userProfileIcon}
                          alt="User Profile"
                          class="verbaflo-global-profile-image"
                        />
                      {:else}
                        <User />
                      {/if}
                    </div>
                  {/if}
                </div>
              {/each}
            {/if}
          </div>

          <!-- Thinking Indicator -->
          {#if $showThinking && $selected_communication === "message" && ($activeTyping ?? true)}
            <div class="verbaflo-global-thinking-container">
              <img
                src={clientLogoUrl}
                alt="Bot Profile"
                class="verbaflo-global-bot-profile"
              />
              <!-- Message Content -->
              <div class="verbaflo-global-message-box">
                <div class="verbaflo-global-bot-name">
                  <span>{botName}</span>
                </div>
                <div class="verbaflo-global-typing-indicator">
                  <ThinkingIndicator />
                </div>
              </div>
            </div>
          {/if}
        {/if}
      </div>

      {#if $showSocketLoader}
        <div class="verbaflo-loader">
          <div class="verbaflo-loader-text">Reconnecting, please wait...</div>
        </div>
      {:else if $user_details_form_submitted && $selected_communication === "message"}
        <div>
          {#if $messages.filter((m) => m.role === "user").length === 0 && userPresetMessages.length}
            <div class="verbaflo-preset-messages">
              {#each userPresetMessages as message}
                <button
                  class="verbaflo-preset-btn verbaflo-preset-btn-text"
                  style={`--accent-color: ${accentColor};`}
                  on:click={() => {
                    newMessage = message;
                    handleSubmit(null);
                  }}
                >
                  {message}
                </button>
              {/each}
            </div>
          {/if}
        </div>
        <div class="verbaflo-footer">
          <p class="verbaflo-footer-text">Powered by</p>
          <FooterLogo />
          <a
            href="https://verbaflo.ai"
            target="_blank"
            rel="noopener noreferrer"
            class="verbaflo-footer-link"
          >
            VerbaFlo.
          </a>
        </div>

        {#if $showInput && $selected_communication === "message"}
          <div class="verbaflo-input-container">
            <form on:submit={handleSubmit} class="verbaflo-form">
              <input
                class="verbaflo-input"
                placeholder={$showSocketLoader
                  ? "Reconnecting, please wait"
                  : "Type message"}
                bind:value={newMessage}
                on:keydown={handleKeyDown}
                on:focus={() => {
                  if (windowWidth < 700) {
                    isKeyboardOpen = true;
                    document.body.style.setProperty(
                      "height",
                      "100vh",
                      "important"
                    );
                    document.body.style.setProperty(
                      "overflow",
                      "hidden",
                      "important"
                    );
                    document.body.style.setProperty(
                      "position",
                      "fixed",
                      "important"
                    );
                    document.body.style.setProperty(
                      "width",
                      "100%",
                      "important"
                    );
                  }
                }}
                on:blur={() => {
                  if (windowWidth < 700) {
                    isKeyboardOpen = false;
                    if (!$isOpen) {
                      document.body.style.removeProperty("height");
                      document.body.style.removeProperty("overflow");
                      document.body.style.removeProperty("position");
                      document.body.style.removeProperty("width");
                    }
                  }
                }}
                disabled={$showSocketLoader}
              />
              <button
                type="submit"
                disabled={$showSocketLoader}
                class="verbaflo-send-btn"
                style="--accent-color: {accentColor};"
              >
                <SendIcon />
              </button>
            </form>
          </div>
        {/if}
      {/if}

      <div>
        {#if $selected_communication === "email"}
          <button
            type="button"
            on:click={() => window.open(emailLink)}
            style="--accent-color: {accentColor};"
            class="verbaflo-communication-detail-container"
          >
            <EmailIcon />
            <p class="verbaflo-communication-detail-container-text">
              Send Email
            </p>
          </button>
        {/if}
        {#if $selected_communication === "phone"}
          <button
            type="button"
            on:click={(e) => {
              e.preventDefault();
              window.location.href = `tel:${phoneLink.replace(/[^\d+]/g, "")}`;
            }}
            style="--accent-color: {accentColor};"
            class="verbaflo-communication-detail-container"
          >
            <PhoneIcon />
            <p class="verbaflo-communication-detail-container-text">Call Us</p>
          </button>
        {/if}

        {#if $selected_communication === "whatsapp"}
          <button
            on:click={() => window.open(whatsappLink)}
            type="button"
            style="--accent-color: {accentColor};"
            class="verbaflo-communication-detail-container"
          >
            <WhatsappIcon />
            <p class="verbaflo-communication-detail-container-text">
              Open Whatsapp
            </p>
          </button>
        {/if}
      </div>

      <div class="verbaflo-communications-container">
        {#if emailLink || phoneLink || whatsappLink}
          <button
            on:click={() => handleCommunicationToggle("message")}
            type="button"
            style="--accent-color: {accentColor}; color: {accentColor};"
            class={$selected_communication === "message"
              ? "verbaflo-communication-icon-selected"
              : "verbaflo-communication-icon"}
          >
            <MessageSolidIcon />
            {#if $selected_communication === "message"}
              <div
                class="verbaflo-communication-horizontal-line"
                style="--accent-color: {accentColor};"
              />
            {/if}
          </button>
        {/if}
        {#if modalityConfig.mail_enabled && emailLink}
          <button
            on:click={() => handleCommunicationToggle("email")}
            type="button"
            style="--accent-color: {accentColor};"
            class={$selected_communication === "email"
              ? "verbaflo-communication-icon-selected"
              : "verbaflo-communication-icon"}
          >
            <EmailSolidIcon />
            {#if $selected_communication === "email"}
              <div
                class="verbaflo-communication-horizontal-line"
                style="--accent-color: {accentColor};"
              />
            {/if}
          </button>
        {/if}
        {#if modalityConfig.call_enabled && phoneLink}
          <button
            on:click={() => handleCommunicationToggle("phone")}
            type="button"
            style="--accent-color: {accentColor};"
            class={$selected_communication === "phone"
              ? "verbaflo-communication-icon-selected"
              : "verbaflo-communication-icon"}
          >
            <PhoneSolidIcon />
            {#if $selected_communication === "phone"}
              <div
                class="verbaflo-communication-horizontal-line"
                style="--accent-color: {accentColor};"
              />
            {/if}
          </button>
        {/if}
        {#if modalityConfig.whatsapp_enabled && whatsappLink}
          <button
            on:click={() => handleCommunicationToggle("whatsapp")}
            type="button"
            style="--accent-color: {accentColor};"
            class={$selected_communication === "whatsapp"
              ? "verbaflo-communication-icon-selected"
              : "verbaflo-communication-icon"}
          >
            <WhatsappSolidIcon />
            {#if $selected_communication === "whatsapp"}
              <div
                class="verbaflo-communication-horizontal-line"
                style="--accent-color: {accentColor};"
              />
            {/if}
          </button>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  :global(.verbaflo-widget-container) {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    z-index: 99999 !important;
  }

  :global(.verbaflo-widget-communication-btn-container) {
    position: fixed !important;
    bottom: 16px !important;
    left: 16px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 16px !important;
  }

  :global(.verbaflo-widget-communication-btn svg) {
    width: 30px !important;
    height: 30px !important;
    flex-shrink: 0 !important;
    min-width: 30px !important;
  }

  :global(.verbaflo-widget-communication-btn) {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    cursor: pointer !important;
    width: 60px !important;
    height: 60px !important;
    padding: 0 14px !important;
    margin: 0 !important;
    border: none !important;
    border-radius: 50px !important;
    background-color: #f67e35 !important;
    overflow: hidden !important;
  }

  /* Only apply hover effects on screens larger than 768px */
  @media (min-width: 768px) {
    :global(.verbaflo-widget-communication-btn) {
      transition: width 0.3s ease-in-out !important;
    }

    :global(.verbaflo-widget-communication-btn:hover) {
      width: 160px !important;
    }

    :global(
        .verbaflo-widget-communication-btn:hover
          .verbaflo-widget-communication-text
      ) {
      width: auto !important;
      opacity: 1 !important;
    }
  }

  :global(.verbaflo-widget-communication-text) {
    width: 0 !important;
    opacity: 0 !important;
    white-space: nowrap !important;
    margin-left: 8px !important;
    transition: all 0.3s ease-in-out !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
  }

  :global(.verbaflo-widget-btn) {
    position: fixed !important;
    bottom: 1rem !important;
    right: 1rem !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 50% !important;
    border: 1px solid #14161b !important;
    background-color: #14161b !important;
    color: #ffffff !important;
    padding: 0 !important;
    margin: 0 !important;
    text-transform: none !important;
    line-height: 1.25rem !important;
    transition: transform 0.2s ease-in-out !important;
  }
  :global(.verbaflo-widget-btn:hover) {
    transform: scale(1.05) !important;
    color: #1f2937 !important;
  }
  :global(.verbaflo-widget-btn:disabled) {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  :global(.verbaflo-widget-btn-dot) {
    position: absolute !important;
    right: 4px !important;
    top: 4px !important;
    background-color: red !important;
    border-radius: 50% !important;
    width: 14px !important;
    height: 14px !important;
  }

  :global(.verbaflo-widget-chat-bubble-container) {
    position: absolute !important;
    left: -150% !important;
    top: -50% !important;
    display: flex !important;
    align-items: center !important;
    column-gap: 10px !important;
    flex-shrink: 0 !important;
    background-color: white !important;
    color: #051133 !important;
    width: 100% !important;
    min-width: max-content !important;
    padding: 8px 12px !important;
    border: 1px solid black !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  :global(.verbaflo-widget-chat-bubble-popup-message) {
    color: #051133 !important;
    padding: 0px !important;
    margin: 0px !important;
    line-height: normal !important;
    font-size: 14px !important;
  }

  :global(.verbaflo-chat-popup) {
    position: absolute !important;
    bottom: 5rem !important;
    right: 0 !important;
    width: 400px !important;
    height: calc(100vh - 7rem) !important;
    max-height: 620px !important;
    background-color: white !important;
    border-radius: 1.5rem !important;
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    display: flex !important;
    flex-direction: column !important;
    font-size: 0.875rem !important;
    transition: all 0.3s ease-in-out !important;
  }

  :global(.verbaflo-chat-header) {
    background-color: var(--accent-color) !important;
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
    height: 52px !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 12px !important;
    position: relative !important;
    flex-shrink: 0 !important;
  }

  :global(.verbaflo-back-btn) {
    display: none !important;
    background: none !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
  }

  @media (max-width: 768px) {
    :global(.verbaflo-chat-popup) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
      width: 100% !important;
      max-height: 100% !important;
      border-radius: 0 !important;
      margin: 0 !important;
    }

    :global(.verbaflo-chat-container) {
      height: calc(100% - 52px) !important;
      padding-bottom: 20px !important;
    }

    :global(.verbaflo-chat-header) {
      border-radius: 0 !important;
      height: 62px !important;
    }

    :global(.verbaflo-back-btn) {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    :global(.verbaflo-input-container) {
      position: sticky !important;
      bottom: 0 !important;
      background: white !important;
      margin-bottom: 10px !important;
      padding-bottom: 10px !important;
    }
  }

  :global(.verbaflo-chat-header-content) {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
    box-sizing: border-box !important;
  }

  :global(.verbaflo-chat-header-content > *) {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
  }

  :global(.verbaflo-chat-left) {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
  }
  :global(.verbaflo-client-logo) {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1) !important;
    margin-left: 8px !important;
  }
  :global(.verbaflo-chat-title) {
    font-weight: 400 !important;
    color: white !important;
    margin: 0px !important;
    margin-left: 8px !important;
    font-size: 16px !important;
    letter-spacing: 0.05em !important;
    line-height: normal !important;
  }
  :global(.verbaflo-menu-container) {
    margin-right: 12px !important;
    position: relative !important;
  }
  :global(.verbaflo-menu-icon) {
    width: 28px !important;
    height: 20px !important;
    cursor: pointer !important;
    background: transparent !important;
    outline: none !important;
    border: none !important;
  }
  :global(.verbaflo-options-menu) {
    position: absolute !important;
    right: 0px !important;
    margin-top: 64px !important;
    padding: 8px 16px !important;
    display: flex !important;
    align-items: center !important;
    gap: 0px !important;
    width: max-content !important;
    background: white !important;
    border: 1px solid #e5e7eb !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    z-index: 50 !important;
    cursor: pointer !important;
  }
  :global(.verbaflo-new-chat-btn) {
    width: 100% !important;
    text-align: left !important;
    font-size: 14px !important;
    color: #4b5563 !important;
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    padding: 4px !important;
    margin: 0px !important;
    line-height: normal !important;
  }
  :global(.verbaflo-new-chat-btn:hover) {
    background-color: #f3f4f6 !important;
    margin: 0px !important;
  }

  :global(.verbaflo-widget-btn:disabled) {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  :global(.verbaflo-chat-container) {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100% !important;
    padding: 20px 12px 12px 12px !important;
  }

  :global(.verbaflo-chat-messages-container) {
    display: flex !important;
    flex-direction: column !important;
    row-gap: 1rem !important;
    width: 100% !important;
  }

  :global(.verbaflo-message) {
    display: flex !important;
    align-items: flex-start !important;
    max-width: 100% !important;
  }

  :global(.verbaflo-message-left) {
    justify-content: flex-start !important;
  }

  :global(.verbaflo-message-right) {
    justify-content: flex-end !important;
  }

  :global(.verbaflo-bot-message-parent-wrapper) {
    display: flex !important;
    align-items: flex-start !important;
    gap: 8px !important;
    z-index: 10 !important;
  }

  :global(.verbaflo-bot-message-parent) {
    background-color: #f6f6f6 !important;
    border-radius: 0.5rem !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
    padding: 10px !important;
    max-width: 280px !important;
    word-break: break-word !important;
  }

  :global(.verbaflo-property-card-container) {
    display: flex !important;
    flex-direction: column !important;
    gap: 12px !important;
  }

  :global(.verbaflo-bot-name) {
    font-weight: 500 !important;
    color: #051133 !important;
    font-size: 14px !important;
    margin: 0px !important;
    line-height: normal !important;
  }

  :global(.verbaflo-bot-message-container) {
    display: flex !important;
    flex-direction: column !important;
    word-break: break-word !important;
    font-size: 14px !important;
    margin: 0px !important;
  }

  :global(.verbaflo-bot-message-container *) {
    color: black !important;
    word-break: break-word !important;
    font-size: 14px !important;
    margin: 0px !important;
  }

  :global(.verbaflo-bot-message-link) {
    text-decoration: underline !important;
  }

  :global(.verbaflo-bot-profile) {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
  }

  :global(.verbaflo-timestamp) {
    color: #737373 !important;
    font-size: 0.75rem !important;
    text-align: left !important;
    margin-top: 0.25rem !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
  }

  :global(.verbaflo-quick-replies) {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  :global(.verbaflo-quick-replies button) {
    max-width: fit-content !important;
    color: var(--accent-color) !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 9999px !important;
    padding: 0.25rem 0.5rem !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    background: none !important;
    cursor: pointer !important;
  }

  :global(.verbaflo-quick-replies button:hover) {
    background-color: var(--accent-color) !important;
    color: #ffffff !important;
  }

  :global(.verbaflo-divider-container) {
    display: flex !important;
    align-items: center !important;
    gap: 0.75rem !important;
    width: 100% !important;
    margin-bottom: 0.75rem !important;
  }

  :global(.verbaflo-divider-container hr) {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    flex-grow: 1;
  }

  :global(.verbaflo-divider-text) {
    color: #737373 !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    white-space: nowrap !important;
  }

  :global(.verbaflo-global-message-container) {
    display: flex !important;
    align-items: start !important;
    gap: 8px !important;
    position: relative !important;
  }

  :global(.verbaflo-global-agent-message-wrapper) {
    width: 100% !important;
  }

  :global(.verbaflo-global-agent-join-message) {
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    margin-bottom: 12px !important;
  }

  :global(.verbaflo-global-divider) {
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  :global(.verbaflo-global-join-text) {
    color: #737373 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    white-space: nowrap !important;
  }
  :global(.verbaflo-global-profile-image) {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important;
  }
  :global(.verbaflo-global-message-content) {
    display: flex !important;
    flex-direction: column !important;
  }
  :global(.verbaflo-global-bot-message-parent-container) {
    background-color: #f6f6f6 !important;
    border-radius: 8px !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    padding: 10px !important;
    max-width: 280px !important;
    word-break: break-word !important;
  }
  :global(.verbaflo-global-bot-message-container) {
    font-weight: 500 !important;
  }

  :global(.verbaflo-global-bot-message-container *) {
    color: #051133 !important;
  }
  :global(.verbaflo-global-agent-name) {
    font-weight: 500 !important;
    color: #051133 !important;
  }
  :global(.verbaflo-global-user-message-container) {
    background-color: var(--accent-color) !important;
    border-radius: 8px !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    padding: 10px !important;
    max-width: 280px !important;
    word-break: break-word !important;
  }

  :global(.verbaflo-global-user-message-container *) {
    color: white !important;
    font-size: 14px !important;
    margin: 0px !important;
  }
  :global(.verbaflo-global-bot-link) {
    color: black !important;
    text-decoration: underline !important;
  }
  :global(.verbaflo-global-user-link) {
    color: white !important;
    text-decoration: underline !important;
  }
  :global(.verbaflo-global-timestamp) {
    font-size: 12px !important;
    color: #737373 !important;
    text-align: left !important;
    margin-top: 4px !important;
  }
  :global(.verbaflo-global-user-timestamp) {
    text-align: right !important;
  }
  :global(.verbaflo-global-thinking-container) {
    display: flex !important;
    align-items: flex-start !important;
    gap: 8px !important;
    z-index: 10 !important;
  }
  :global(.verbaflo-global-bot-profile) {
    display: inline-block !important;
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important;
  }
  :global(.verbaflo-global-message-box) {
    background-color: #f6f6f6 !important;
    border-radius: 8px !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    padding: 8px 12px !important;
    max-width: 280px !important;
  }
  :global(.verbaflo-global-bot-name) {
    font-weight: 500 !important;
    color: #051133 !important;
  }
  :global(.verbaflo-global-typing-indicator) {
    display: inline-flex !important;
    flex-direction: column !important;
    word-break: break-word !important;
  }

  :global(.verbaflo-loader) {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0.5rem 0 !important;
  }
  :global(.verbaflo-loader-text) {
    color: #051133 !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    text-align: center !important;
    border-radius: 0.5rem !important;
    max-width: 20rem !important;
  }

  :global(.verbaflo-preset-messages) {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    margin-right: 1.25rem !important;
    margin-bottom: 0.75rem !important;
  }

  :global(.verbaflo-preset-btn) {
    margin-bottom: 0.5rem !important;
    padding: 0.5rem !important;
    background-color: #ffffff !important;
    color: var(--accent-color) !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 0.25rem !important;
    cursor: pointer !important;
  }

  :global(.verbaflo-preset-btn *) {
    color: var(--accent-color) !important;
  }
  :global(.verbaflo-preset-btn:hover *) {
    color: #ffffff !important;
  }

  :global(.verbaflo-preset-btn:hover) {
    background-color: var(--accent-color) !important;
    color: #ffffff !important;
  }

  :global(.verbaflo-preset-btn-text) {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  :global(.verbaflo-input-container) {
    display: flex !important;
    align-items: center !important;
    gap: 0.75rem !important;
    border: 1px solid #ededed !important;
    border-radius: 0.5rem !important;
    margin: 0 12px !important;
    padding: 4px !important;
  }

  :global(.verbaflo-form) {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    gap: 0.5rem !important;
    z-index: 20 !important;
  }
  :global(.verbaflo-input) {
    flex-grow: 1 !important;
    font-size: 1rem !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #051133 !important;
    font-family: "Satoshi", sans-serif !important;
    font-weight: 400 !important;
    transform: scale(0.95) !important;
  }

  :global(.verbaflo-input::placeholder) {
    color: #737373 !important;
    font-weight: 400 !important;
    font-family: "Satoshi", sans-serif !important;
    opacity: 0.8 !important;
  }

  :global(.verbaflo-input:focus) {
    border: none;
    outline: none;
    box-shadow: none;
  }
  :global(.verbaflo-send-btn) {
    height: 2.5rem !important;
    width: 2.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 0.5rem !important;
    background-color: var(--accent-color) !important;
    outline: none !important;
    border: none !important;
  }

  :global(.verbaflo-send-btn:focus) {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  :global(.verbaflo-send-icon) {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  :global(.verbaflo-footer) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    gap: 0.25rem !important;
    margin: 6px 0px !important;
  }

  :global(.verbaflo-footer-text) {
    color: #737373 !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    margin: 0px !important;
  }

  :global(.verbaflo-logo) {
    width: 1.25rem !important;
    height: 1.25rem !important;
    border-radius: 50% !important;
  }

  :global(.verbaflo-footer-link) {
    text-decoration: none !important;
    color: #051133 !important;
    font-weight: bold !important;
    font-size: 12px !important;
    cursor: pointer !important;
    margin: 0px !important;
  }
  :global(.verbaflo-communication-detail-container) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 16px auto !important;
    column-gap: 6px !important;
    color: white !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 8px !important;
    padding: 10px 22px !important;
    background-color: var(--accent-color) !important;
    font-size: 14px !important;
    max-width: fit-content !important;
    box-shadow: 0px 3px 12px rgba(5, 17, 51, 0.18) !important;
    cursor: pointer !important;
  }

  :global(.verbaflo-communication-detail-container-text) {
    color: white !important;
    font-size: 14px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  :global(.verbaflo-communications-container) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    column-gap: 28px !important;
    background: #ffffff !important;
    margin: 10px auto !important;
  }

  :global(.verbaflo-communication-icon) {
    color: #737373 !important;
    transition: color 0.3s ease !important;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
  }

  :global(.verbaflo-communication-icon-selected) {
    color: var(--accent-color) !important;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: column !important;
    row-gap: 3px !important;
  }

  :global(.verbaflo-communication-horizontal-line) {
    background-color: var(--accent-color) !important;
    width: 100% !important;
    height: 2px !important;
    border: transparent !important;
    border-radius: 50px !important;
    margin: 0px !important;
  }

  :global(.verbaflo-bot-message-container a) {
    text-decoration: underline !important;
  }

  :global(.verbaflo-bot-message-container ol) {
    list-style-type: decimal !important;
    padding: 0px 16px !important;
    margin-top: 8px !important;
  }
  :global(.verbaflo-bot-message-container ul) {
    list-style-type: disc !important;
    padding: 0px 16px !important;
    margin-top: 4px !important;
  }

  /* Move this outside the media query and make it conditional on isOpen */
  :global(.verbaflo-chat-popup:not([hidden]) + .verbaflo-widget-btn) {
    display: none !important;
  }
</style>
