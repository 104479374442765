<script>
  import { marked } from "marked";
  import DotCarousel from "./DotCarousel.svelte";
  import he from "he";

  export let accentColor = "";
  export let cdnUrl = "";
  export let message = [];
</script>

<!-- Message Container -->
<div class="verbaflo-property-message-container">
  <p class="verbaflo-property-message-text">
    {#await marked.parse(message?.message?.replace(/\n/g, "</br>")) then parsedMessage}
      {@html he
        .decode(parsedMessage)
        .replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')
        .replace(
          /(\+\d{1,3}[\s\-.]?)?((?:[0-9][\s\-.]?){6,}[0-9])/g,
          '<a href="tel:$&">$&</a>'
        )}
    {/await}
  </p>
</div>

<!-- Carousel Container -->
<div class="verbaflo-property-carousel-container">
  <DotCarousel {cdnUrl}>
    {#each message?.property_json as property}
      <div class="verbaflo-property-card">
        <div class="verbaflo-property-card-image-container">
          <img
            class="verbaflo-property-card-image"
            src={typeof property?.image === "string"
              ? property?.image
              : property?.image?.length > 0
                ? property?.image[0]
                : `${cdnUrl}/assets/images/property_fallback_image.png`}
            alt={property?.property_name}
          />
        </div>
        <div class="verbaflo-property-card-details">
          <h3 class="verbaflo-property-card-title">
            {property?.property_name}
          </h3>
          <div
            class="verbaflo-property-card-info-row verbaflo-property-card-info-row--pt"
          >
            <span class="verbaflo-property-label">Type:</span>
            <p class="verbaflo-property-value">{property?.room_name || "-"}</p>
          </div>
          {#if property?.price}
            <div class="verbaflo-property-card-info-row">
              <span class="verbaflo-property-label">Rent:</span>
              <p class="verbaflo-property-value">
                {property?.price ? `£${property?.price}` : "-"}
              </p>
            </div>
          {/if}
          <div class="verbaflo-property-card-amenities">
            <p
              title={property?.amenities?.length
                ? property?.amenities?.join(", ")
                : "-"}
            >
              <span class="verbaflo-property-label">Amenities:</span>
              {property?.amenities?.length
                ? property?.amenities?.slice(0, 4).join(", ")
                : "-"}
            </p>
          </div>
          <button
            style="background-color: {accentColor} !important;"
            class="verbaflo-property-card-button"
            on:click={() => {
              window.open(
                property?.source_link,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            Visit Property
          </button>
        </div>
      </div>
    {/each}
  </DotCarousel>
</div>

<style>
  :global(.verbaflo-property-message-container) {
    background-color: #f6f6f6 !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0 !important;
    padding: 10px !important;
    max-width: 280px !important;
  }

  :global(.verbaflo-property-message-text) {
    font-size: 0.875rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  :global(.verbaflo-property-carousel-container) {
    margin-top: 0.25rem !important;
    background-color: #f6f6f6 !important;
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.06) !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0 !important;
    padding: 10px !important;
    max-width: 280px !important;
    overflow: hidden !important;
    position: relative !important;
    isolation: isolate !important;
    -webkit-transform: translateZ(0) !important;
    transform: translateZ(0) !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
  }

  :global(.verbaflo-property-card) {
    background-color: #ffffff !important;
    width: 100% !important;
    flex-shrink: 0 !important;
    max-width: 230px !important;
    display: flex !important;
    flex-direction: column !important;
    border: 1px solid #e5e7eb !important;
    border-radius: 0.5rem !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    transform: translateZ(0) !important;
  }

  :global(.verbaflo-property-card-image-container) {
    height: 150px !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
    object-fit: contain !important;
  }

  :global(.verbaflo-property-card-image) {
    width: 100% !important;
    min-height: 150px !important;
    height: 150px !important;
    object-fit: fill !important;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  :global(.verbaflo-property-card-details) {
    padding: 0.75rem !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 6px !important;
    font-size: 14px !important;
    height: 100% !important;
  }

  :global(.verbaflo-property-card-title) {
    font-weight: 500 !important;
    margin: 0 !important;
  }

  :global(.verbaflo-property-card-info-row) {
    display: flex !important;
    gap: 0.25rem !important;
    align-items: center !important;
    margin: 0 !important;
  }

  :global(.verbaflo-property-card-info-row--pt) {
    padding-top: 0.5rem !important;
  }

  :global(.verbaflo-property-label) {
    font-weight: 500 !important;
  }

  :global(.verbaflo-property-value) {
    font-weight: 300 !important;
    margin: 0 !important;
  }

  :global(.verbaflo-property-card-amenities) {
    display: flex !important;
    gap: 0.25rem !important;
    flex-wrap: wrap !important;
    margin-bottom: 10px !important;
  }

  :global(.verbaflo-property-card-button) {
    color: #ffffff !important;
    cursor: pointer !important;
    font-weight: 500 !important;
    width: fit-content !important;
    padding: 6px 12px !important;
    border-radius: 0.25rem !important;
    margin-top: auto !important;
    border: none !important;
  }
</style>
