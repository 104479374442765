<svg
  width="20"
  height="4"
  viewBox="0 0 20 4"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.40406 2.3999C8.40406 3.25158 9.09449 3.94201 9.94617 3.94201C10.7979 3.94201 11.4883 3.25158 11.4883 2.3999C11.4883 1.54821 10.7979 0.857788 9.94617 0.857788C9.09449 0.857788 8.40406 1.54821 8.40406 2.3999Z"
    fill="white"
  />
  <path
    d="M16.0603 2.3999C16.0603 3.25158 16.7507 3.94201 17.6024 3.94201C18.4541 3.94201 19.1445 3.25158 19.1445 2.3999C19.1445 1.54821 18.4541 0.857788 17.6024 0.857788C16.7507 0.857788 16.0603 1.54821 16.0603 2.3999Z"
    fill="white"
  />
  <path
    d="M0.747809 2.3999C0.747809 3.25158 1.43824 3.94201 2.28992 3.94201C3.1416 3.94201 3.83203 3.25158 3.83203 2.3999C3.83203 1.54821 3.1416 0.857788 2.28992 0.857788C1.43824 0.857788 0.747809 1.54821 0.747809 2.3999Z"
    fill="white"
  />
</svg>
