export interface ChatMessage {
  message: string;
  role: "user" | "assistant" | "agent";
  timestamp: string;
  agentName?: string;
  property_json?: any;
  quick_replies?: [];
  kind?: string;
}

interface UrlPattern {
  cityIndex: number;
  propertyIndex: number;
  minSegments: number;
}

interface ClientUrlConfig {
  [domain: string]: UrlPattern;
}

export const clientUrlPatterns: ClientUrlConfig = {
  "www.vitastudent.com": {
    cityIndex: 2,
    propertyIndex: 3,
    minSegments: 4,
  },
  "www.downingstudents.com": {
    cityIndex: 1,
    propertyIndex: 2,
    minSegments: 3,
  },
};
